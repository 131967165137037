import { createContext, useContext, useMemo } from 'react';
import { useFetchPersonalizationQuery } from '../services/featureinfo.api';
import { FeatureInfo } from '../stores/types/personalization.interface';

type ContextType = {
  allowPowermanEdit: boolean; // Powerman Edit Allowed
  allowMultiCompanyView: boolean;
  allowStateOfChargeChargersView: boolean; // State of Charge View Allowed
  allowSparkSignals: boolean;
  allowPayterView: boolean; // State of payter view
  allowNyserdaReports: boolean; // Feature to show/hide nyserda reports dropdown option
};

const initialState: ContextType = {
  allowPowermanEdit: false,
  allowMultiCompanyView: false,
  allowStateOfChargeChargersView: false,
  allowSparkSignals: false,
  allowPayterView: false,
  allowNyserdaReports: false,
};

const AllowedFeaturesContext = createContext(initialState);

export const useAllowedFeatures = () => {
  return useContext(AllowedFeaturesContext);
};

const useProvideAllowedFeatures = (): ContextType => {
  const { data: personalization } = useFetchPersonalizationQuery();
  const getFeatureFlagsMap = useMemo(() => {
    const featureFlagsMap: { [key: string]: boolean } = {};
    personalization?.features?.forEach((feature: FeatureInfo) => {
      featureFlagsMap[feature.featureName] = feature.enabled;
    });
    return featureFlagsMap;
  }, [personalization]);
  return {
    allowPowermanEdit: getFeatureFlagsMap.Y2024_powerman_edit || false,
    allowStateOfChargeChargersView:
      getFeatureFlagsMap.Y2024_state_of_charge_chargers_view || false,
    allowMultiCompanyView: getFeatureFlagsMap.Y2024_multi_company_view || false,
    allowSparkSignals: getFeatureFlagsMap.Y2024_spark_signals || false,
    allowPayterView: getFeatureFlagsMap.Y2024_payter || false,
    allowNyserdaReports: getFeatureFlagsMap.Y2024_nyserda || false,
  };
};

export const AllowedFeaturesProvider = (props: any) => {
  const { children } = props;
  const res = useProvideAllowedFeatures();

  return (
    <AllowedFeaturesContext.Provider value={res}>
      {children}
    </AllowedFeaturesContext.Provider>
  );
};
