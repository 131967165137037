import { addMonths, getMonth, getYear, subMonths } from 'date-fns';
import { memo, useEffect, useState } from 'react';
import { ChevronLeft, ChevronRight } from '../../../assets/icons';
import { formatDate } from '../../../utils/Date.Util';
import { Icon } from '../Icon.component';
import { Skeleton } from '../Skeleton/Skeleton.component';

export interface DateSelectorProps {
  endDate?: Date;
  format?: string;
  onChange?: Function;
  durationMonth?: number;
  isLoading: boolean;
}

export const DateSelector = memo(
  ({
    endDate = new Date(),
    format = 'LLL yyyy',
    onChange,
    durationMonth = 12,
    isLoading = false,
  }: DateSelectorProps) => {
    const [_endDate, setEndDate] = useState(endDate);
    const today = new Date();

    // Need to update the state when endDate changes
    // This will happen when back button on browser is clicked
    useEffect(() => {
      setEndDate(endDate);
    }, [endDate]);

    const getStartDate = (date: Date) => {
      return subMonths(date, durationMonth - 1);
    };
    const getText = () => {
      return `${formatDate(getStartDate(_endDate), format)} - ${formatDate(
        _endDate,
        format,
      )}`;
    };

    const preMonth = () => {
      const newEndDate = subMonths(_endDate, 1);
      onChange && onChange([getStartDate(newEndDate), newEndDate]);
      setEndDate(newEndDate);
    };
    const nextMonth = () => {
      const newEndDate = addMonths(_endDate, 1);
      onChange && onChange([getStartDate(newEndDate), newEndDate]);
      setEndDate(newEndDate);
    };

    const hasNextMonthDate = () => {
      if (getYear(_endDate) < getYear(today)) {
        return true;
      }
      if (getMonth(_endDate) < getMonth(today)) {
        return true;
      }

      return false;
    };

    return (
      <div className='flex items-center'>
        {isLoading && <Skeleton height='h-5' width='w-48' />}
        {!isLoading && (
          <>
            <button
              className='w-[28px] h-[28px] flex justify-center items-center hover:bg-grey2 rounded-full'
              onClick={preMonth}
              data-testid='prev-button'
            >
              <Icon src={ChevronLeft} />
            </button>
            <div className='text-grey6 text-sm font-semibold w-44 flex place-content-center'>
              {getText()}
            </div>
            {hasNextMonthDate() && (
              <button
                className='w-[28px] h-[28px] flex justify-center items-center hover:bg-grey2 rounded-full'
                onClick={nextMonth}
                data-testid='next-button'
              >
                <Icon src={ChevronRight} />
              </button>
            )}
          </>
        )}
      </div>
    );
  },
);
