import { t } from 'i18next';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import {
  DayPrice,
  RuleTypes,
  ScheduleType,
} from '../../../stores/types/price.interface';
import {
  CheckBoxData,
  FormInput,
  ColorType,
  GroupDirection,
  Icon,
  Label,
  LabelType,
  RadioGroup,
  Switch,
} from '../../_ui';
import { PricingCalendar } from '../PricingCalendar.component';
import { getRuleLabel } from '../utils';
import PriceFormStepsHead from './PriceFormStepsHead.component';
import TieredPricing from './TieredPricing/TieredPricing.component';
import { Dollar, ErrorWarningtraiangle, Info } from '../../../assets/icons';
import { InputType, ValidationType } from '../../_ui/Input.component';
import { checkIdleFeeError } from '../../_ui/modal/utils';
import { IconSize } from '../../../constant/IconSize.constant';
import { getIdleFeesEnabled } from '../../../stores/selectors/deployment.selector';
import { Company } from '../../../stores/types';

export interface IdleFeeDetails {
  enable: boolean;
  idleRate: string | number;
}
interface IProps {
  dayPrices: DayPrice[] | undefined;
  scheduleItems: Array<any>;
  tierPrices: Array<any>;
  priceType: string;
  setPriceType: Function;
  setTierPrices: Function;
  scheduleType: ScheduleType | RuleTypes;
  handleScheduleTypeChange: Function;
  handleCalendarChange: Function;
  disabled?: boolean;
  idleFeeDetails: IdleFeeDetails;
  setIdleFeeDetails: Function;
  companyInfo?: Company;
  triggerIdleRateCheck: Function;
  isError?: boolean;
}
const PriceForm = ({
  dayPrices,
  scheduleItems,
  scheduleType,
  tierPrices,
  priceType,
  setPriceType,
  setTierPrices,
  idleFeeDetails,
  setIdleFeeDetails,
  handleScheduleTypeChange,
  handleCalendarChange,
  disabled = false,
  companyInfo,
  triggerIdleRateCheck,
  isError = false,
}: IProps) => {
  // Idle fee feature flag
  const idleFeesEnabled: boolean = useSelector(getIdleFeesEnabled);

  const ruleItems = useMemo(() => {
    return Object.keys(RuleTypes).map((key) => ({
      id: key,
      label: getRuleLabel(key),
      selected: key === priceType,
      disabled,
    }));
  }, [priceType, disabled]);

  const handleRuleTypeChange = (items: CheckBoxData[]) => {
    items.forEach((item) => {
      if (item && item.selected && item?.id) {
        setPriceType(item.id as RuleTypes);
      }
    });
  };

  const pricingDesc = useMemo(() => {
    let desc;
    let desc2;
    if (priceType === RuleTypes.FREE) {
      desc = t('pricing-step2-desc');
    } else if (priceType === RuleTypes.TIME_OF_USE) {
      desc = t('pricin_schedule_line_1');
      desc2 = t('pricin_schedule_line_2');
    } else {
      desc = t('pricing_step2_tiered_desc');
    }
    return { desc, desc2 };
  }, [priceType]);

  const renderIdleFeeWarn = () => (
    <div className='flex flex-row gap-2 mt-2 p-4 bg-grey1 rounded-[8px] max-w-[538px] items-start'>
      <Icon
        size={IconSize.SIZE_24x24}
        src={ErrorWarningtraiangle}
        color={ColorType.WARNING1}
      />
      {(companyInfo?.currency === 'CAD' || companyInfo?.currency === 'USD') && (
        <Label
          text={t('idle_fee_warning')}
          type={LabelType.LABEL_S_MEDIUM}
          color={ColorType.GREY6}
        />
      )}
      {companyInfo?.currency === 'JMD' && (
        <Label
          text={t('idle_fee_warning_for_jmd')}
          type={LabelType.LABEL_S_MEDIUM}
          color={ColorType.GREY6}
        />
      )}
      {companyInfo?.currency === 'BBD' && (
        <Label
          text={t('idle_fee_warning_for_bbd')}
          type={LabelType.LABEL_S_MEDIUM}
          color={ColorType.GREY6}
        />
      )}
    </div>
  );

  const renderIdleFeeError = () => (
    <div className='flex flex-row gap-2 mt-2 p-2 bg-grey1 max-w-[538px] rounded-[8px]'>
      <Icon size={IconSize.SIZE_20x20} src={Info} color={ColorType.WARNING1} />
      <div className='flex flex-col gap-2'>
        {(companyInfo?.currency === 'CAD' ||
          companyInfo?.currency === 'USD') && (
          <Label
            text={t('idle_fee_error')}
            type={LabelType.LABEL_S_MEDIUM}
            color={ColorType.GREY6}
          />
        )}
        {companyInfo?.currency === 'JMD' && (
          <Label
            text={t('idle_fee_error_for_jmd')}
            type={LabelType.LABEL_S_MEDIUM}
            color={ColorType.GREY6}
          />
        )}
        {companyInfo?.currency === 'BBD' && (
          <Label
            text={t('idle_fee_error_for_bbd')}
            type={LabelType.LABEL_S_MEDIUM}
            color={ColorType.GREY6}
          />
        )}
        {(companyInfo?.currency === 'CAD' ||
          companyInfo?.currency === 'USD') && (
          <Label
            text={t('idle_fee_warning')}
            type={LabelType.LABEL_S_MEDIUM}
            color={ColorType.GREY6}
          />
        )}
        {companyInfo?.currency === 'JMD' && (
          <Label
            text={t('idle_fee_warning_for_jmd')}
            type={LabelType.LABEL_S_MEDIUM}
            color={ColorType.GREY6}
          />
        )}
        {companyInfo?.currency === 'BBD' && (
          <Label
            text={t('idle_fee_warning_for_bbd')}
            type={LabelType.LABEL_S_MEDIUM}
            color={ColorType.GREY6}
          />
        )}
      </div>
    </div>
  );

  const checkIdleFeeWarning = () => {
    const { idleRate } = idleFeeDetails;
    if (!idleRate) {
      return false;
    }
    if (companyInfo?.currency === 'CAD' || companyInfo?.currency === 'USD') {
      return (
        !Number.isNaN(Number(idleRate)) &&
        (Number(idleRate) < 0.05 || Number(idleRate) > 1.0)
      );
    }
    if (companyInfo?.currency === 'JMD') {
      return (
        !Number.isNaN(Number(idleRate)) &&
        (Number(idleRate) < 7.0 || Number(idleRate) > 150.0)
      );
    }
    if (companyInfo?.currency === 'BBD') {
      return (
        !Number.isNaN(Number(idleRate)) &&
        (Number(idleRate) < 0.05 || Number(idleRate) > 2.0)
      );
    }
  };

  return (
    <>
      <PriceFormStepsHead
        title={t('pricing-step1-title')}
        desc={t('pricing-step1-desc')}
      />
      <div
        className='flex flex-row items-center h-[40px]'
        data-testid='pricingTypes'
      >
        <div className='w-[148px]'>
          <Label
            className='min-w-[148px]'
            text={t('rule_type')}
            type={LabelType.LABEL_S}
            color={ColorType.GREY6}
          />
        </div>
        <RadioGroup
          direction={GroupDirection.Horizontal}
          defaultItems={ruleItems}
          onChange={handleRuleTypeChange}
        />
      </div>
      <PriceFormStepsHead
        title={t('pricing-step2-title')}
        desc={pricingDesc.desc}
        desc2={pricingDesc.desc2}
      />
      {priceType === RuleTypes.TIME_OF_USE && (
        <>
          <div
            className='flex flex-row items-center h-[40px]'
            data-testid='pricingDetails'
          >
            <div className='w-[148px]'>
              <Label
                className='min-w-[148px]'
                text={t('pricing_type')}
                type={LabelType.LABEL_S}
                color={ColorType.GREY6}
              />
            </div>
            <RadioGroup
              direction={GroupDirection.Horizontal}
              defaultItems={scheduleItems}
              onChange={handleScheduleTypeChange}
            />
          </div>
          <div className='mt-[16px]'>
            <PricingCalendar
              scheduleType={scheduleType}
              dayPrices={dayPrices}
              onChange={handleCalendarChange}
              disabled={disabled}
            />
          </div>
        </>
      )}
      {priceType === RuleTypes.TIERED && (
        <TieredPricing
          tierPrices={tierPrices}
          setTierPrices={setTierPrices}
          disabled={disabled}
        />
      )}
      {/* Idle fees setup configuration */}
      {idleFeesEnabled && (
        <>
          <div className='flex flex-col gap-2 mt-[32px]'>
            <div className='flex flex-row gap-2 items-baseline'>
              <Label text={t('pricing-step3-title')} type={LabelType.LABEL_M} />
              <Label
                text={`(${t('optional')})`}
                type={LabelType.LABEL_M_MEDIUM}
                color={ColorType.GREY6}
              />
            </div>
            <Label
              text={t('idle_fee_sub_title')}
              type={LabelType.BODY3}
              color={ColorType.GREY6}
            />
          </div>
          <div className='flex flex-row gap-x-14 mt-3'>
            <div className='flex flex-row gap-2 items-center'>
              <Label
                text={t('idle_fee_label')}
                type={LabelType.LABEL_S}
                color={ColorType.GREY6}
              />
              <Switch
                value={idleFeeDetails.enable}
                onChange={(enable: boolean) => {
                  setIdleFeeDetails({
                    idleRate: enable ? idleFeeDetails.idleRate : '',
                    enable,
                  });
                }}
                hideLabel
                dataTestId='idleFeeSwitch'
              />
            </div>
            <div className='flex flex-row gap-x-3 items-center'>
              <FormInput
                dataTestId='idleFeeInput'
                placeholder='0.00'
                onChange={(event: any) => {
                  setIdleFeeDetails({
                    ...idleFeeDetails,
                    idleRate: event.target.value,
                  });
                  triggerIdleRateCheck({
                    idleRate: event.target.value,
                  });
                }}
                defaultValue={idleFeeDetails.idleRate}
                validationType={ValidationType.IDLE_FEE}
                icon={Dollar}
                iconColor={
                  idleFeeDetails.idleRate ? ColorType.GREY6 : ColorType.GREY3
                }
                width={
                  !checkIdleFeeError(idleFeeDetails, companyInfo?.currency)
                    ? '88px'
                    : '107px'
                }
                showOutline={idleFeeDetails.enable}
                disabled={!idleFeeDetails.enable}
                showErrorIcon={idleFeeDetails.enable}
                inputType={InputType.FLOAT_NUMBER}
                companyCurrency={companyInfo?.currency}
              />
              <Label
                text={t('idle_fee_hints')}
                type={LabelType.BODY3}
                color={
                  idleFeeDetails.enable ? ColorType.GREY6 : ColorType.GREY3
                }
              />
            </div>
          </div>
          {idleFeeDetails.enable &&
            checkIdleFeeError(idleFeeDetails, companyInfo?.currency) &&
            isError &&
            renderIdleFeeError()}
          {idleFeeDetails.enable &&
            !checkIdleFeeError(idleFeeDetails, companyInfo?.currency) &&
            !isError &&
            checkIdleFeeWarning() &&
            renderIdleFeeWarn()}
        </>
      )}
      {/* Idle fees setup configuration end */}
    </>
  );
};

export default PriceForm;
