/* eslint-disable react-hooks/exhaustive-deps */
import React, { memo, useEffect, useMemo, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
// @ts-ignore
import { useTranslation } from 'react-i18next';
import {
  Link,
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from 'react-router-dom';
import { Country } from '../../lib/country-state';
import {
  ButtonType,
  ColorType,
  Dropdown,
  FormInput,
  Label,
  LabelType,
  Snackbar,
  getHexColorByType,
} from '../_ui';
import { getCurrentTheme } from '../../stores/selectors/theme.selector';
import { AlertPosition, AlertType } from '../_ui/snack-bar/Snack-Bar.component';
import { useKeyPress, useAuth } from '../../hooks';
import { validateEmail } from '../_ui/modal/utils';

export const LoginUserNameStep = memo((props: any) => {
  const { t } = useTranslation();
  const location = useLocation();

  const isEnterPress = useKeyPress('Enter');
  const auth = useAuth();
  const [email, setEmail] = useState<string>(props.email || '');
  const emailChanged = async (evt: any) => {
    setEmail(evt?.target?.value);
  };
  // the country code is coming from the dropdown
  const [phoneNumberCountryCode, setPhoneNumberCountryCode] =
    useState<string>('1');
  const [countryPhoneList, setCountryPhoneList] = useState<any[]>([]);
  const theme = useSelector(getCurrentTheme);
  const [isLoading, setLoading] = useState(false);

  const isPhoneNumber = () => {
    return /^\d{2,}$/.test(email);
  };

  const showSnackBar = (message: string) => {
    Snackbar.show({
      message,
      position: AlertPosition.BOTTOM,
      alertType: AlertType.ERROR,
      duration: 3000,
    });
  };

  const goNext = () => {
    if (isLoading) {
      return;
    }
    setLoading(true);
    auth.signIn(
      validateEmail(email) ? email : `+${phoneNumberCountryCode}${email}`,
    );
  };

  useEffect(() => {
    if (auth.error && props.isActive) {
      setLoading(false);
      showSnackBar(auth.error);
      auth.cleanError('');
    }
  }, [auth.error]);

  useEffect(() => {
    if (!props.isActive) return;
    setLoading(false);
    if (auth.cognitoUser) {
      props.nextStep();
    }
  }, [auth.cognitoUser]);

  useEffect(() => {
    if (isEnterPress && props.isActive) {
      goNext();
    }
  }, [isEnterPress]);

  const isPhone = isPhoneNumber();
  // TODO: change display style
  const inputVal = email;
  // isPhone && parsePhoneNumber(`${phoneNumberCountryCode}${email}`).isValid()
  //   ? parsePhoneNumber(`${phoneNumberCountryCode}${email}`).formatNational()
  //   : email;
  const countryList = useMemo(() => {
    return Country.getAllCountries().map((obj) => ({
      ...obj,
      flagAndCode: `${obj.flag} +${obj.phonecode}`,
      id: obj.isoCode,
      selected: obj.isoCode === 'CA',
    }));
  }, []);

  if (!props.isActive) return null;
  return (
    <div>
      <div className='mt-[0.5rem] pb-8'>
        <Label
          type={LabelType.H3}
          color={ColorType.BLACK}
          text={t('login_login')}
          className='mb-1'
        />
        <Label
          type={LabelType.BODY2}
          color={ColorType.GREY6}
          text={t('login_enter_email_or_phone')}
        />
      </div>
      <div className='pb-10 flex'>
        {isPhone && (
          <div className='mr-2 h-12'>
            <Dropdown
              headerWidth={100}
              headerHeight='48px'
              label='flagAndCode'
              items={countryList}
              onItemClick={(item: any, index: number) => {
                setPhoneNumberCountryCode(item.phonecode);
              }}
              labelTypeHighlighted={LabelType.DROPDOWN_HEADER}
              chevdownIconHighlightColor={ColorType.GREY6}
              headerHighLightClassName='bg-grey1 border-grey3 rounded h-12'
              headerClassName='bg-grey1 border-grey3 rounded h-12'
            />
          </div>
        )}
        <FormInput
          placeholder={t('login_email_placeholder')}
          onChange={emailChanged}
          props={{
            autoFocus: true,
            readOnly: !!props.email,
            value: inputVal,
          }}
          height='48px'
          width='100%'
          disabled={isLoading}
          dataTestId='login-email'
        />
      </div>
      <button
        disabled={isLoading}
        className={`flex rounded justify-center h-12 items-center w-full pt-3 pb-3 mb-[0.5rem] 
          ${
            isLoading ? 'bg-grey0 hover:bg-grey0' : 'bg-brand2 hover:bg-brand3'
          }`}
        onClick={goNext}
        style={{
          backgroundColor: isLoading ? '' : theme.navigationSelectedColor,
        }}
        data-testid='login-button'
      >
        <Label
          type={LabelType.LABEL_M}
          color={isLoading ? ColorType.GREY3 : ColorType.WHITE}
          text={t('login_login')}
        />
      </button>
      <div>
        <Link to={`/register${location.search || ''}`}>
          <span
            className='text-sm font-medium underline'
            style={{
              color: theme.navigationSelectedColor,
            }}
          >
            {t('login_create_new_account')}
          </span>
        </Link>
      </div>
    </div>
  );
});
