import { useTranslation } from 'react-i18next';
import _ from 'lodash';
import { useEffect, useMemo } from 'react';
import { ColorType, FormInput, Icon, Label, LabelType } from '../../_ui';
import { InputType } from '../../_ui/Input.component';
import {
  Tooltip,
  TooltipContent,
  TooltipTrigger,
} from '../../_ui/Tooltip.component';
import { Info } from '../../../assets/icons';
import { IconSize } from '../../../constant/IconSize.constant';
import { isDuplicateLoadName, unmanagedLoadNames } from '../utils';
import { Breaker, Circuit } from '../../../stores/types/pm.interface';

export interface UnmanagedLoadProp {
  data: {
    breaker: Breaker;
    circuit: Circuit;
  };
  loadData: any;
  activeIndex: number;
  handleInputChange: (key: string, value: any) => void;
  isEdit: boolean;
}

export const UnmanagedLoad = ({
  data,
  loadData,
  activeIndex,
  handleInputChange,
  isEdit,
}: UnmanagedLoadProp) => {
  const { t } = useTranslation();

  const nameValidation = () => {
    if (!loadData[activeIndex].name) {
      return false;
    }

    if (
      isDuplicateLoadName(
        loadData,
        activeIndex,
        isEdit,
        data.circuit.mainBreakers,
      )
    ) {
      return false;
    }
    return true;
  };

  const fixedAmperageValidation = (fixedAmperage: any) => {
    return _.isNumber(Number(fixedAmperage)) && fixedAmperage > 0;
  };

  useEffect(() => {
    // generate suggested name for unmanaged load
    if (!loadData[activeIndex].name) {
      const existingSavedNames = unmanagedLoadNames(
        data.circuit?.mainBreakers ?? [],
        [],
      );
      let suggestedLoadName = '';
      let newBreakerNo = existingSavedNames.length + 1;
      while (!suggestedLoadName) {
        loadData[activeIndex].name = `Unmanaged load ${newBreakerNo}`;
        if (
          !isDuplicateLoadName(
            loadData,
            activeIndex,
            isEdit,
            data.circuit.mainBreakers,
          )
        ) {
          suggestedLoadName = `Unmanaged load ${newBreakerNo}`;
          handleInputChange('name', suggestedLoadName);
        } else {
          newBreakerNo += 1;
        }
      }
    }
  }, [activeIndex]);

  return (
    <>
      <div className='flex flex-col gap-4'>
        <FormInput
          defaultValue={loadData[activeIndex].name}
          label={t('pm_load_name_label')}
          placeholder={t('pm_load_name_placeholder')}
          inputType={InputType.CHARGELAB_ACCEPTED_CHARACTER}
          onChange={(event: any) => {
            handleInputChange('name', event.target.value?.trim());
          }}
          width='100%'
          errorLabel={t('pm_unmanaged_load_name_error')}
          onValidation={() => nameValidation}
          dataTestId='unmanagedLoadName'
        />
      </div>
      <div className='flex flex-col pb-4 gap-2'>
        <div className='flex flex-row gap-1'>
          <Label
            text={t('unmanaged_load_modal_field_2')}
            type={LabelType.LABEL_M}
          />
          <Tooltip placement='top-start'>
            <TooltipTrigger>
              <Icon src={Info} size={IconSize.SIZE_24x24} />
            </TooltipTrigger>
            <TooltipContent>
              <Label
                text={t('pm_amperage_fixed_tooltip')}
                type={LabelType.BODY3}
                color={ColorType.WHITE}
              />
            </TooltipContent>
          </Tooltip>
        </div>

        <div className='flex flex-row gap-2'>
          <FormInput
            showErrorIcon
            defaultValue={loadData[activeIndex].fixedAmperage}
            placeholder='0'
            inputType={InputType.NUMBER}
            onChange={(event: any) => {
              handleInputChange('fixedAmperage', event.target.value);
            }}
            props={{ style: { width: '58px' } }}
            onValidation={fixedAmperageValidation}
            wrapperClassName='inline-block'
            dataTestId='fixedAmperage'
          />
          <Label
            type={LabelType.BODY3}
            color={ColorType.BLACK}
            text={t('pm_amps')}
            className='mt-2.5'
          />
        </div>
      </div>
    </>
  );
};
