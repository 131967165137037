import { SerializedError } from '@reduxjs/toolkit';
import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query';
import { createContext, useContext, useEffect, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation, useSearchParams } from 'react-router-dom';
import { StringParam, useQueryParam } from 'use-query-params';
import { RoutePath } from '../routes';
import { Api, getSwithingCompanyinvalidates } from '../services/base.api';
import { useLazyFetchCompaniesQuery } from '../services/company.api';
import { SCOPE_TYPE } from '../services/utils';
import { Company } from '../stores/types';
import { pushUserProperties } from '../utils/GoogleAnalytics';
import { USER_ROLE, useAuth } from './useAuth';
import { useNavigateWithSearchParam } from './useNavigateWithSearchParam';

type QueryStatus = {
  isFetching: boolean; // Query is currently fetching, but might have data from an earlier request.
  isLoading: boolean; // Query is currently loading for the first time. No data yet.
  isError: boolean; // Query is currently in "error" state.
  isSuccess: boolean; // Query has data from a successful load.
  isUninitialized: boolean; // Query has not started yet.
  error: FetchBaseQueryError | SerializedError | undefined; // error obj
};

type ContextType = {
  isMultiViewActive: boolean; // is in multi company view
  companyId: string | null | undefined; // last selected company
  companies: Company[] | undefined; // users company list
  queryStatus: QueryStatus | undefined; // company query status
  switchCompany: (companyId: string, route?: string) => void; // switch to a new company
  hasMultiCompanies: boolean; // is user has more than 1 company access
  locationsCount: number; // number of locations of company
  chargersCount: number; // numbers of chargers of company
  /**
   * Chaotic scopes:
   * Currently, the usage of scopes on the backend is inconsistent.
   * In some cases, scopes are used to check user permissions. For support users, ALL can override COMPANY.
   * For example, in the chargers API, if you are a support user, the results returned by scope ALL and
   * COMPANY are same.
   *
   * In other cases, scopes differentiate between accessing content for all companies or a single company.
   * For instance, with PM APIs, if you want to access a specific company's PM settings, you must use
   * scope:COMPANY. Using scope:ALL will result in an error.
   *
   * In yet other cases, scopes are used to return results based on user permissions. For example,
   * in the company API, if you are a support user and send scope:COMPANY, you will receive the list of
   * companies you have joined. If you send scope:ALL, you will receive the list of all companies.
   *
   * So, I have created two scope variables here. Please use them as needed:
   * userScope: Defines the scope based on whether the user is a Support user.
   * Support -> ALL, Normal -> Company
   *
   * companyScope: Defines the scope based on whether it is currently Multi-View.
   * Multi-view -> All, Single-view -> Company
   *
   * When you need to modify the scope, please choose the scope carefully and test the returned
   * results for both support and normal users.
   */
  userScope: SCOPE_TYPE | undefined;
  companyScope: SCOPE_TYPE | undefined;
};

const initalState: ContextType = {
  isMultiViewActive: false,
  companyId: undefined,
  companies: undefined,
  queryStatus: undefined,
  switchCompany: () => {},
  hasMultiCompanies: false,
  locationsCount: 0,
  chargersCount: 0,
  userScope: undefined,
  companyScope: undefined,
};

const CompanyContext = createContext(initalState);

export const useCompany = () => {
  return useContext(CompanyContext);
};

const useProvideCompany = () => {
  const [companyId, setCompanyId] = useQueryParam('companyId', StringParam);
  const [searchParams, setSearchParams] = useSearchParams();
  const [
    triggerFetchCompany,
    {
      data: companies,
      isLoading,
      isFetching,
      isSuccess,
      isError,
      isUninitialized,
      error,
    },
  ] = useLazyFetchCompaniesQuery();
  const auth = useAuth();
  const dispatch = useDispatch();
  const currentLocation = useLocation();
  const { navigateWithCompanyId } = useNavigateWithSearchParam();

  const isMultiViewActive = useMemo(() => {
    return companyId?.toUpperCase() === 'ALL';
  }, [companyId]);

  const userScope = useMemo(() => {
    if (auth.user && auth.role) {
      return auth.role === USER_ROLE.SUPPORT
        ? SCOPE_TYPE.ALL
        : SCOPE_TYPE.COMPANY;
    }
    return undefined;
  }, [auth.role, auth.user]);

  const companyScope = useMemo(() => {
    return isMultiViewActive ? SCOPE_TYPE.ALL : SCOPE_TYPE.COMPANY;
  }, [isMultiViewActive]);

  useEffect(() => {
    if (auth.user && userScope) {
      triggerFetchCompany(userScope);
    }
  }, [auth, triggerFetchCompany, userScope]);

  const hasMultiCompanies = useMemo(() => {
    return (companies && companies.length > 1) || false;
  }, [companies]);

  /**
   * Checks if route is not accessible from the sidebar menu
   * @param pathname route that needs to be checked
   * @returns boolean
   */
  const isPageNotAccessibleFromSideBar = (pathname: string) => {
    const [, path, id] = pathname.split('/');
    // length check is needed as /chargers and /chargers/:id have same base route
    return (
      ((path === RoutePath.CHARGERS || path === RoutePath.VEHICLES) && id) ||
      path === RoutePath.PRICING_EDIT
    );
  };

  const resetSearchParams = () => {
    const deletedSearchKey = ['locationId', 'status', 'maintenanceFlag'];
    deletedSearchKey.forEach((searchStr) => {
      if (searchParams.has(searchStr)) {
        searchParams.delete(searchStr);
      }
    });

    setSearchParams(searchParams);
  };

  const switchCompany = (
    newCompanyId: string,
    route: string = RoutePath.OVERVIEW,
  ) => {
    // reset all query params
    resetSearchParams();
    if (newCompanyId.toUpperCase() === 'ALL') {
      setCompanyId(newCompanyId);
      navigateWithCompanyId(`/${RoutePath.OVERVIEW}`, newCompanyId);
    } else {
      const company = companies?.find(
        (_company) => _company.id === newCompanyId,
      );
      if (company) {
        setCompanyId(newCompanyId);
        pushUserProperties(auth.user.username, {
          company_name: company.name,
        });
        // Navigate to overview page if current page is not accessible from sidebar menu
        isPageNotAccessibleFromSideBar(currentLocation.pathname)
          ? navigateWithCompanyId(`/${route}`, company.id)
          : '';
      }
    }
    // invalidateTags
    dispatch(Api.util.invalidateTags(getSwithingCompanyinvalidates()));
  };

  useEffect(() => {
    if (companies && companies?.length > 0 && !isMultiViewActive) {
      const companyIdFromLocalStorage =
        auth.user.attributes.profile?.lastActiveCompanyId;
      const companyDetail = companies.find(
        (ele: any) => ele.id === companyIdFromLocalStorage,
      );
      pushUserProperties(auth.user.username, {
        company_name: companyDetail?.name,
      });

      if (
        companyIdFromLocalStorage === '' ||
        !companies.some((comp) => comp.id === companyIdFromLocalStorage)
      ) {
        auth.updateCompanyId(companies[0].id);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [companies]);

  const locationsCount = useMemo(() => {
    const res = companies?.reduce((previousValue, currentValue) => {
      return (
        previousValue +
        (currentValue && currentValue.noOfLocations
          ? currentValue.noOfLocations
          : 0)
      );
    }, 0);
    return res || 0;
  }, [companies]);

  const chargersCount = useMemo(() => {
    const res = companies?.reduce((previousValue, currentValue) => {
      return (
        previousValue +
        (currentValue && currentValue.noOfChargers
          ? currentValue.noOfChargers
          : 0)
      );
    }, 0);
    return res || 0;
  }, [companies]);

  return {
    isMultiViewActive,
    companies,
    companyId,
    queryStatus: {
      isLoading,
      isFetching,
      isSuccess,
      isError,
      isUninitialized,
      error,
    },
    switchCompany,
    hasMultiCompanies,
    locationsCount,
    chargersCount,
    userScope,
    companyScope,
  };
};

export const CompanyProvider = (props: any) => {
  const { children } = props;
  const res = useProvideCompany();

  return (
    <CompanyContext.Provider value={res}>{children}</CompanyContext.Provider>
  );
};
