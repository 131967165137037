import { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { getCurrentTheme } from '../../stores/selectors/theme.selector';
import { Label, LabelType } from './Label.component';
import { ColorType, getHexColorByType } from './index';

interface InputProps {
  disableLabel?: string;
  enableLabel?: string;
  labelColor?: ColorType;
  value?: boolean;
  onChange?: Function;
  className?: string;
  disabled?: boolean;
  dataTestId?: string;
  hideLabel?: boolean;
}

export const Switch = memo(
  ({
    disableLabel = 'Off',
    enableLabel = 'On',
    labelColor = ColorType.BLACK,
    value = false,
    onChange,
    className = '',
    disabled = false,
    dataTestId,
    hideLabel = false,
  }: InputProps) => {
    const { t } = useTranslation();
    const theme = useSelector(getCurrentTheme);

    const updateChecked = () => {
      !disabled && onChange && onChange(!value);
    };

    return (
      <div className={`flex flex-row items-center gap-2 ${className}`}>
        <div
          data-testid={dataTestId}
          onClick={updateChecked}
          className={`relative w-10 h-5 rounded-full flex items-center ${
            !disabled && 'cursor-pointer'
          }`}
          style={{
            backgroundColor:
              disabled || !value
                ? getHexColorByType(ColorType.GREY2)
                : theme.brand_2,
          }}
        >
          <div
            className={`w-4 h-4 left-0.5 top-0.5 rounded-full bg-white absolute transform transition-transform ${
              value ? 'translate-x-5' : ''
            }`}
          />
        </div>
        {!hideLabel && (
          <span className='self-center'>
            <Label
              text={!value ? disableLabel : enableLabel}
              type={LabelType.BODY3}
              color={labelColor}
            />
          </span>
        )}
      </div>
    );
  },
);
