import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ExternalLink } from '../../../assets/icons';
import {
  Button,
  ButtonSize,
  ButtonType,
  ColorType,
  FormInput,
  Label,
  LabelType,
  useGlobalModalContext,
} from '../../_ui';
import { InputType, ValidationType } from '../../_ui/Input.component';

import { useUpdatePayterIdentifierMutation } from '../../../services/payouts.api';
import { Company } from '../../../stores/types';
import { Form } from '../../_ui/Form.component';

interface IPROPS {
  companyDetails: Company | undefined;
}
const ConnectPayterAccount = ({ companyDetails }: IPROPS) => {
  const { t } = useTranslation();
  const { hideModal } = useGlobalModalContext();
  const [payterAccountDomain, setPayterAccountDomain] = useState<string>('');

  const handleCompanyDomain = (event: any) => {
    setPayterAccountDomain(event.target.value);
  };

  const [updatePayterIdentifier, updateCompanyQueryResult] =
    useUpdatePayterIdentifierMutation();

  const onConnectPayterClick = () => {
    window.open('https://payter.us/apply/ChargeLab.html');
  };

  const handleSave = () => {
    updatePayterIdentifier({
      body: {
        ...companyDetails,
        payterAccountIdentifier: payterAccountDomain,
      },
      toast: {
        successTrans: 'successfully_linked_payter_account',
      },
    });
  };

  return (
    <Form
      onSubmit={handleSave}
      queryResult={updateCompanyQueryResult}
      onQuerySuccess={() => {
        hideModal();
      }}
      onQueryFailed={() => {
        hideModal();
      }}
    >
      <Label
        text={t('connecting_payter_terminal_2step_process')}
        type={LabelType.BODY3}
        color={ColorType.BLACK}
        className='mb-6'
      />
      <Label
        text={t('step1_submit_payter_account_form')}
        type={LabelType.LABEL_M}
        color={ColorType.BLACK}
        className='mb-2'
      />
      <Label
        text={t('creating_merchant_account_desc1')}
        type={LabelType.BODY3}
        color={ColorType.BLACK}
      />

      <Button
        label={t('create_payer_merchant_account')}
        type={ButtonType.SECONDARY}
        icon={ExternalLink}
        iconRightAlign
        iconColor={ColorType.BRAND2}
        className='w-full mb-6 mt-4 !justify-center'
        onClick={onConnectPayterClick}
        labelContainerCss='grow-0'
        dataTestId='create-payter-mercahnt-account-button'
      />
      <Label
        text={t('step2_submit_payter_account_domain')}
        type={LabelType.LABEL_M}
        color={ColorType.BLACK}
        className='mb-2'
      />
      <div className='mb-2'>
        <Label
          htmlText={t('after_completing_step1_desc')}
          type={LabelType.BODY3}
          color={ColorType.BLACK}
        />
      </div>
      <Label
        text={t('make_sure_this_domain_entered_correctly')}
        type={LabelType.BODY3}
        color={ColorType.BLACK}
      />
      <Label
        text={t('payter_account_domain')}
        type={LabelType.LABEL_M}
        color={ColorType.BLACK}
        className='mt-6 mb-2'
      />

      <FormInput
        validationType={ValidationType.NOT_EMPTY}
        placeholder={t('domain')}
        defaultValue={payterAccountDomain}
        onChange={(event: any) => {
          handleCompanyDomain(event);
        }}
        inputType={InputType.DOMAIN}
        width='100%'
        showErrorIcon={!payterAccountDomain}
        dataTestId='connect-payter-account-domain-input'
      />
      <div className='flex flex-col mt-6 pb-[20px] sticky bottom-0 bg-white'>
        <Button
          iconColor={ColorType.GREY3}
          label={t('save')}
          type={ButtonType.PRIMARY}
          disabled={!payterAccountDomain}
          size={ButtonSize.SMALL_FULL}
          isSumbit
          isLoading
          className='flex items-center w-full'
          dataTestId='connect-payter-account-domain-button'
        />
      </div>
    </Form>
  );
};

export default ConnectPayterAccount;
