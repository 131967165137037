import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { AddPlus2, ExternalLink, PayterIcon } from '../../../assets/icons';
import { IconSize } from '../../../constant/IconSize.constant';
import { payterContext } from '../../../hooks/usePayter';
import {
  Button,
  ButtonType,
  ColorType,
  Icon,
  Label,
  LabelType,
  MODAL_TYPES,
  useGlobalModalContext,
} from '../../_ui';
import RenderLearnMoreText from './RenderLearnMoreText.component';

const TerminalHeader = () => {
  const { t } = useTranslation();
  const { showModal, hideModal } = useGlobalModalContext();

  const payterConsumer = useContext(payterContext);
  const { isPayterConnected } = payterConsumer;

  const onAddPayterClick = () => {
    window.open('https://payter.us/cases/chargelab_terminal_activation.html');
    hideModal();
  };

  // handleUnlinkPayter is hidden in 080 release, will be used in future

  // const handleUnlinkPayter = () => {
  //   showModal(MODAL_TYPES.ALERT_MODAL, {
  //     height: 'max-content',
  //     title: t('are_you_sure'),
  //     icon: ErrorWarningtraiangle,
  //     iconColor: ColorType.NEGATIVE1,
  //     message: (
  //       <>
  //         <Label
  //           text={t('unlink_connected_payter_desc1')}
  //           type={LabelType.BODY3}
  //           color={ColorType.GREY6}
  //         />
  //         <Label
  //           className='mt-2'
  //           text={t('unlink_connected_payter_desc2')}
  //           type={LabelType.BODY3}
  //           color={ColorType.GREY6}
  //         />
  //       </>
  //     ),
  //     mutationHook: useUpdatePayterIdentifierMutation,
  //     mutationParams: {
  //       body: {
  //         ...companyDetails,
  //         payterAccountIdentifier: '',
  //       },
  //       toast: {
  //         successTrans: 'successfully_unlinked_payter_account',
  //       },
  //     },
  //     buttons: [
  //       {
  //         label: t('cancel'),
  //         type: ButtonType.TERTIARY,
  //         size: ButtonSize.SMALL,
  //         className: 'min-w-fit w-20',
  //       },
  //       {
  //         label: t('unlink_payter_account'),
  //         type: ButtonType.DESTRUCTIVE,
  //         size: ButtonSize.SMALL_FULL,
  //         className: 'min-w-fit w-20',
  //         isSubmit: true,
  //         disableDelayTime: 3000,
  //         disableDelayLabel: t('wait_3sec_to_unlink'),
  //       },
  //     ],
  //     shouldCloseOnOverlayClick: false,
  //   });
  // };

  const addPayterTerminal = () => {
    showModal(MODAL_TYPES.INFO_MODAL, {
      title: t('add_terminal'),
      overflow: 'auto',
      width: '540px',
      height: 'fit-content',
      onRenderBody: () => (
        <>
          <div className='mb-2'>
            <Label
              text={t('add_terminal_desc1')}
              type={LabelType.BODY3}
              color={ColorType.BLACK}
            />
          </div>
          <div className='mb-2'>
            <Label
              htmlText={t('add_terminal_desc2')}
              type={LabelType.BODY3}
              color={ColorType.BLACK}
            />
          </div>
          <div className='h-[40px] mb-5 mt-6'>
            <Button
              label={t('add_payter_terminal')}
              type={ButtonType.SECONDARY}
              icon={ExternalLink}
              iconRightAlign
              iconColor={ColorType.BRAND2}
              className='w-full mb-6 mt-4 !justify-center'
              labelContainerCss='grow-0'
              onClick={onAddPayterClick}
            />
          </div>
        </>
      ),
      onClose: hideModal,
      shouldCloseOnOverlayClick: false,
    });
  };

  return (
    <>
      <div className='flex flex-row justify-between mb-5'>
        <div>
          <Label
            text={t('payter_terminal_title')}
            type={LabelType.H4}
            color={ColorType.BLACK}
            className='mb-1'
            dataTestId='terminal-header-title'
          />
          <div className='flex items-center'>
            <Label
              text={t('powered_by')}
              type={LabelType.LABEL_XS_MEDIUM}
              color={ColorType.GREY5}
            />
            <Icon
              src={PayterIcon}
              size={IconSize.SIZE_52x12}
              className='ml-1.5'
            />
          </div>
        </div>
        {isPayterConnected && (
          <div className='flex items-center'>
            <Button
              icon={AddPlus2}
              iconColor={ColorType.GREY6}
              label={t('add_terminal')}
              type={ButtonType.TERTIARY}
              onClick={addPayterTerminal}
            />
            {/* This feature needs to required in the future */}
            {/* <Menu placement='bottom-end' icon={More}>
              <MenuItem
                label={t('unlink_payter_account')}
                onClick={handleUnlinkPayter}
                labelColor={ColorType.NEGATIVE1}
              />
            </Menu> */}
          </div>
        )}
      </div>
      <div className='mb-5'>
        <RenderLearnMoreText text='allow_driver_charging_session' />
      </div>
    </>
  );
};

export default TerminalHeader;
