import { useTranslation } from 'react-i18next';
import { Breaker } from '../../stores/types/pm.interface';
import { Label, LabelType } from '../_ui/Label.component';
import { ColorType, Pill } from '../_ui';
import {
  prependWiringText,
  transformChargerType,
  transformWiringForSplitPhase,
} from './utils';

export interface ChargerDetailsProp {
  data: {
    charger: any;
    breaker: Breaker;
    isSplitPhase: boolean;
  };
}
export const ChargerDetailsModal = ({ data }: ChargerDetailsProp) => {
  const { t } = useTranslation();
  return (
    <div className='divide-y divide-grey2 px-1'>
      <div className='flex flex-col pb-4 gap-y-4 px-1'>
        <div className='flex flex-col gap-y-2'>
          <Label
            type={LabelType.LABEL_M}
            text={t('load_modal_field_1')}
            color={ColorType.BLACK}
          />
          <Pill label={data.charger.displayName} labelColor={ColorType.GREY6} />
        </div>
        <div className='flex flex-col gap-y-2'>
          <Label
            type={LabelType.LABEL_M}
            color={ColorType.BLACK}
            text={t('load_modal_field_2')}
          />
          <Label
            type={LabelType.BODY3}
            text={data.charger.model}
            color={ColorType.BLACK}
          />
        </div>
        <div className='flex flex-col gap-y-2'>
          <Label
            type={LabelType.LABEL_M}
            color={ColorType.BLACK}
            text={t('load_modal_field_3')}
          />
          <Label
            type={LabelType.BODY3}
            color={ColorType.BLACK}
            text={transformChargerType(data.charger.type)}
          />
        </div>
        <div className='flex flex-col gap-y-2'>
          <Label
            type={LabelType.LABEL_M}
            color={ColorType.BLACK}
            text={t('load_modal_field_4')}
          />
          <Label
            type={LabelType.BODY3}
            color={ColorType.BLACK}
            text={`${Math.ceil(data.charger.maxAmp)} amps`}
          />
        </div>
        <div className='flex flex-col gap-y-2'>
          <Label
            type={LabelType.LABEL_M}
            color={ColorType.BLACK}
            text={t('load_modal_field_5')}
          />
          <Label
            type={LabelType.BODY3}
            color={ColorType.BLACK}
            text={`${Math.ceil(data.charger.minAmp)} amps`}
          />
        </div>
        <div className='flex flex-col gap-y-2'>
          <Label
            type={LabelType.LABEL_M}
            color={ColorType.BLACK}
            text={t('load_modal_field_6')}
          />
          <Label
            type={LabelType.BODY3}
            color={ColorType.BLACK}
            text={data.charger.zeroAmpSupport ? t('yes') : t('no')}
          />
        </div>
      </div>
      <div className='flex flex-col pt-4 gap-y-4 px-1 pb-5'>
        <div className='flex flex-col gap-y-2'>
          <Label
            type={LabelType.LABEL_M}
            color={ColorType.BLACK}
            text={t('load_modal_field_7')}
          />
          <Label
            type={LabelType.BODY3}
            color={ColorType.BLACK}
            text={data.breaker.dedicatedBreaker ? t('yes') : t('no')}
          />
        </div>
        {data.breaker.dedicatedBreaker && (
          <>
            <div className='flex flex-col gap-y-2'>
              <Label
                type={LabelType.LABEL_M}
                color={ColorType.BLACK}
                text={t('load_modal_field_8')}
              />
              <Label
                type={LabelType.BODY3}
                color={ColorType.BLACK}
                text={`${data.breaker.breakerRating} amps`}
              />
            </div>
            <div className='flex flex-col gap-y-2'>
              <div className='flex flex-row'>
                <Label
                  type={LabelType.LABEL_M}
                  color={ColorType.BLACK}
                  text={t('load_modal_field_9')}
                />
                <div>&nbsp;</div>
                <Label
                  type={LabelType.LABEL_M_MEDIUM}
                  text={t('load_modal_field_10')}
                />
              </div>
              <Label
                type={LabelType.BODY3}
                color={ColorType.BLACK}
                text={`${data.breaker.continuousLoadLimit} amps`}
              />
            </div>
            <div className='flex flex-col gap-y-2'>
              <Label
                type={LabelType.LABEL_M}
                color={ColorType.BLACK}
                text={t('load_modal_field_11')}
              />
              <Label
                type={LabelType.BODY3}
                color={ColorType.BLACK}
                text={data.breaker.breakerSpace}
              />
            </div>
          </>
        )}

        <div className='flex flex-col gap-y-2'>
          <Label
            type={LabelType.LABEL_M}
            color={ColorType.BLACK}
            text={t('load_modal_field_12')}
          />
          <Label
            type={LabelType.BODY3}
            color={ColorType.BLACK}
            text={
              data.isSplitPhase
                ? transformWiringForSplitPhase(data.charger.chargerWiring)
                : prependWiringText(data.charger.chargerWiring)
            }
          />
        </div>
      </div>
    </div>
  );
};
