import React, { memo } from 'react';
import { CheckBox, ColorType } from '..';
import { Label, LabelType } from '../Label.component';
import { GridColumnType } from './enums/Grid-Column-Type.enum';
import { GridColumn } from './types/Grid-Column.interface';

interface InputProps {
  columns: GridColumn[];
  onChange?: Function;
  checkRef?: any;
  selected?: boolean;
}
export const GridHeader = memo(
  ({ columns, onChange, checkRef, selected }: InputProps) => {
    return (
      <thead className='border-t border-b border-grey2'>
        <tr>
          {columns.map((col: GridColumn) => {
            if (col.type === GridColumnType.CHECKBOX) {
              return (
                <th key={col.key} className='text-left py-2.5 pl-3'>
                  <CheckBox
                    onChange={onChange}
                    checkRef={checkRef}
                    selected={selected}
                  />
                </th>
              );
            }
            if (col.type === GridColumnType.RADIOBTN) {
              return (
                <th
                  key={col.key}
                  className='text-left py-2.5 pl-3'
                  aria-label='radio-btn-header-col'
                />
              );
            }
            return (
              <th
                key={col.key}
                className={`text-left py-2.5 pl-3 ${col.class}`}
                // style={{ width: '128px' }}
              >
                <Label
                  type={LabelType.LABEL_S}
                  color={ColorType.GREY6}
                  text={col.title}
                  className='contents'
                />
                {col.secondaryTitle && (
                  <Label
                    type={LabelType.BODY3}
                    color={ColorType.GREY6}
                    text={col.secondaryTitle}
                    className='inline ml-[2px]'
                  />
                )}
              </th>
            );
          })}
        </tr>
      </thead>
    );
  },
);
