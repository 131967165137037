import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ErrorWarningtraiangle } from '../assets/icons';
import {
  ButtonSize,
  ButtonType,
  CheckBox,
  ColorType,
  Label,
  LabelType,
  MODAL_TYPES,
  useGlobalModalContext,
} from '../components/_ui';
import { RoutePath } from '../routes';
import { useLazyFetchCompanyDetailQuery } from '../services/company.api';
import { useLazyFetchPricingModelsQuery } from '../services/pricing.api';
import { BankInfoProvided } from '../stores/types/company.interface';
import { useAuth } from './useAuth';
import { useCompany } from './useCompany';
import { useNavigateWithSearchParam } from './useNavigateWithSearchParam';

// Hook to show missing banking info modal when company have missing bank info + have at least one non-free pricing rule
export const useShowBankingInfoModal = () => {
  const { t } = useTranslation();
  const { showModal } = useGlobalModalContext();
  const auth = useAuth();
  const { companyId, isMultiViewActive } = useCompany();
  const [showBankInfoModal, setShowBankInfoModal] = useState<boolean>(false);

  const [triggerFetchCompanyDetail, { data: company }] =
    useLazyFetchCompanyDetailQuery();
  const { userScope } = useCompany();
  const [
    triggerFetchingPriceModel,
    { data: pricing, isFetching: isPricingFetch },
  ] = useLazyFetchPricingModelsQuery();
  const { navigateWithCompanyId } = useNavigateWithSearchParam();

  const navigateToCompanyPage = () => {
    navigateWithCompanyId(`/${RoutePath.COMPANY}`, companyId);
  };

  useEffect(() => {
    if (companyId && companyId?.toUpperCase() !== 'ALL' && !isMultiViewActive) {
      triggerFetchingPriceModel({ companyId: companyId, scope: userScope! });
    }
  }, [companyId, isMultiViewActive]);
  const handleCheckboxChange = (selected: boolean, index: number) => {
    if (selected) {
      localStorage.setItem(
        `hideModal-${auth.user.username}-${companyId}`,
        selected.toString(),
      );
    }
  };

  const renderModalBody = () => {
    return (
      <div className='ml-[36px] mt-2'>
        <Label
          text={t('banking_info_modal_content_1')}
          type={LabelType.BODY3}
          color={ColorType.BLACK}
        />
        <Label
          text={t('banking_info_modal_content_2')}
          type={LabelType.BODY3}
          color={ColorType.BLACK}
          className='pt-2 pb-4'
        />
        <CheckBox
          label={t('banking_info_modal_checkbox_label')}
          onChange={handleCheckboxChange}
        />
      </div>
    );
  };

  const displayBankInfoModal = () => {
    showModal(MODAL_TYPES.ALERT_MODAL, {
      height: 'max-content',
      title: t('banking_info_modal_title'),
      icon: ErrorWarningtraiangle,
      iconColor: ColorType.WARNING1,
      onRenderBody: renderModalBody,
      buttons: [
        {
          label: t('banking_info_modal_btn_1'),
          type: ButtonType.TERTIARY,
          size: ButtonSize.SMALL,
          className: 'min-w-fit w-20',
          dataTestId: 'bankInfoNotNow',
        },
        {
          label: t('banking_info_modal_btn_2'),
          type: ButtonType.PRIMARY,
          size: ButtonSize.SMALL,
          className: 'min-w-fit w-20',
          onClick: navigateToCompanyPage,
          dataTestId: 'bankInfoGoToCompany',
        },
      ],
      shouldCloseOnOverlayClick: false,
      testId: 'bankInfoAlertModal',
    });
  };

  useEffect(() => {
    if (isMultiViewActive || !companyId) {
      return;
    }
    triggerFetchCompanyDetail({ id: companyId, scope: userScope! });
  }, [companyId, isMultiViewActive, triggerFetchCompanyDetail]);

  useEffect(() => {
    const isBankInfoMissing: boolean = company
      ? company?.bankInfoProvided === BankInfoProvided.MISSING
      : false;

    // nonFreePricing: Indicates if there's at least one non-free pricing rule
    const hasNonFreePricing = pricing
      ? pricing.some(
          (priceObj) => priceObj?.idleRate || priceObj.scheduleType !== 'FREE',
        )
      : false;
    // If user selected "Don't show again checkbox" then hide the modal
    const hideModal =
      localStorage.getItem(`hideModal-${auth.user.username}-${companyId}`) ===
      'true';
    // Don't show model when price data is being fetched
    // Don't show modal when All companies selected on dropdown
    setShowBankInfoModal(
      isPricingFetch || companyId === 'ALL'
        ? false
        : isBankInfoMissing && hasNonFreePricing && !hideModal,
    );
  }, [company, pricing, isPricingFetch]);

  return { showBankInfoModal, displayBankInfoModal };
};
