import React, { memo, useEffect, useMemo, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { useLocation, Link, useSearchParams } from 'react-router-dom';
import parsePhoneNumber from 'libphonenumber-js';

import _ from 'lodash';
import { Hub } from 'aws-amplify';
import { Country } from '../../lib/country-state';
import { useAuth } from '../../hooks';
import {
  Button,
  ButtonSize,
  ButtonType,
  CheckBox,
  ColorType,
  Dropdown,
  FormInput,
  Label,
  LabelType,
  Snackbar,
  getHexColorByType,
} from '../_ui';
import { InputType, ValidationType } from '../_ui/Input.component';
import { AlertPosition, AlertType } from '../_ui/snack-bar/Snack-Bar.component';
import { RoutePath } from '../../routes';
import { getCurrentTheme } from '../../stores/selectors/theme.selector';
import { validateEmail } from '../_ui/modal/utils';
import { useSignUpAnalyticsMutation } from '../../services/google.api';
import { formatPhoneCode } from '../../utils/phone.utils';
import { useNavigateWithSearchParam } from '../../hooks/useNavigateWithSearchParam';

export const RegisterCreateAccount = memo((props: any) => {
  const siteHostTermsOfServiceVersion = '1.0';
  const { t } = useTranslation();
  const theme = useSelector(getCurrentTheme);
  const [searchParams, setSearchParams] = useSearchParams();
  const { navigate } = useNavigateWithSearchParam();
  const location = useLocation();
  const [firstName, setFirstName] = useState<string>('');
  const [lastName, setLastName] = useState<string>('');
  const [isRegisterSuccess, setIsRegisterSuccess] = useState(false);

  const [email, setEmail] = useState<string>(searchParams.get('email') || '');
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const auth = useAuth();

  const [countryCode, setCountryCode] = useState('CA');
  const [nationalNumber, setNationalNumber] = useState<string>('');
  const [triggerSignUpGA] = useSignUpAnalyticsMutation();
  const [tosCheckboxChecked, setTOSCheckboxChecked] = useState(false);
  const countryList = useMemo(() => {
    return Country.getAllCountries().map((obj) => ({
      ...obj,
      id: obj.isoCode,
      flagAndCode: `${obj.flag} ${formatPhoneCode(obj.phonecode)}`,
      selected: obj.isoCode === countryCode,
    }));
  }, [countryCode]);

  const phoneNumber = useMemo(() => {
    const country = countryList.find(
      (_country) => _country.isoCode === countryCode,
    );
    return `${formatPhoneCode(country?.phonecode || '')}${nationalNumber}`;
  }, [countryList, countryCode, nationalNumber]);

  const handleRegisterSubmit = () => {
    setIsLoading(true);
    auth
      .signUp(
        firstName,
        lastName,
        email,
        phoneNumber,
        siteHostTermsOfServiceVersion,
      )
      .then(() => {
        triggerSignUpGA();
      });
  };

  const handleTOSCheckboxToggle = (value: boolean) => {
    setTOSCheckboxChecked(value);
  };

  const onButtonValidation = () => {
    if (isLoading) {
      return false;
    }
    const result = parsePhoneNumber(phoneNumber);
    if (!result?.isValid()) {
      return false;
    }

    if (_.isEmpty(firstName) || _.isEmpty(lastName)) {
      return false;
    }
    if (!validateEmail(email)) {
      return false;
    }
    if (!tosCheckboxChecked) {
      return false;
    }

    return true;
  };

  useEffect(() => {
    const unsubscribe = Hub.listen('auth', (data) => {
      switch (data.payload.event) {
        case 'signUp':
          setIsRegisterSuccess(true);
          props.registerSuccess(true);
          break;
        default:
          break;
      }
    });
    // Cleanup subscription on unmount
    return () => unsubscribe();
  }, []);

  const showSnackBar = (message: string) => {
    Snackbar.show({
      message,
      position: AlertPosition.BOTTOM,
      alertType: AlertType.ERROR,
      duration: 3000,
    });
  };

  useEffect(() => {
    if (auth.error && props.isActive) {
      setIsLoading(false);
      showSnackBar(auth.error);
      auth.cleanError('');
    }
  }, [auth.error]);

  const handleReturnToLogin = () => {
    navigate(`../${RoutePath.LOGIN}${location.search || ''}`, {
      replace: true,
    });
  };

  const renderRegisterDone = () => (
    <div className='w-full'>
      <div className='pb-[40px]'>
        <Label
          type={LabelType.H3}
          color={ColorType.BLACK}
          text={t('register_create_success')}
        />
        <Label type={LabelType.BODY2} text={t('register_to_login')} />
      </div>
      <div className='w-full h-[48px]'>
        <Button
          label={t('register_return')}
          type={ButtonType.PRIMARY}
          onClick={handleReturnToLogin}
          size={ButtonSize.MEDIUM_FULL}
        />
      </div>
    </div>
  );

  const renderRegister = () => (
    <div className='w-full'>
      <div className='pb-[20px]'>
        <Label
          type={LabelType.H3}
          color={ColorType.BLACK}
          text={t('login_create_new_account')}
        />
      </div>
      <div className='flex flex-col gap-[20px] pb-6 w-full'>
        <FormInput
          label={t('register_first_name')}
          defaultValue={firstName}
          placeholder={t('register_first_name')}
          onChange={(e: any) => setFirstName(e.target.value)}
          width='100%'
          validationType={ValidationType.NOT_EMPTY}
          disabled={isLoading}
          dataTestId='firstName'
        />
        <FormInput
          label={t('register_last_name')}
          defaultValue={lastName}
          placeholder={t('register_last_name')}
          onChange={(e: any) => setLastName(e.target.value)}
          width='100%'
          validationType={ValidationType.NOT_EMPTY}
          disabled={isLoading}
          dataTestId='lastName'
        />
        <FormInput
          label={t('register_email')}
          defaultValue={email}
          placeholder={t('register_email_holder')}
          onChange={(e: any) => setEmail(e.target.value)}
          width='100%'
          props={{
            readOnly: !!searchParams.get('email'),
          }}
          validationType={ValidationType.EMAIL}
          disabled={isLoading}
          dataTestId='emailId'
        />
        <div className='flex flex-col gap-2'>
          <Label
            text={t('company_phone_number')}
            type={LabelType.LABEL_M}
            color={ColorType.BLACK}
          />
          <div className='flex flex-row gap-2'>
            <Dropdown
              headerWidth={100}
              label='flagAndCode'
              items={countryList}
              onItemClick={(item: any, index: number) => {
                setCountryCode(item.isoCode);
              }}
              labelTypeHighlighted={LabelType.DROPDOWN_HEADER}
              chevdownIconHighlightColor={ColorType.GREY6}
              headerHighLightClassName='bg-grey1 border-grey3 rounded h-[40px] mr-1.5'
              contentDivHeight={300}
              disabled={isLoading}
              dataTestId='countryCode'
            />
            <FormInput
              showErrorIcon
              onChange={(event: any) => setNationalNumber(event.target.value)}
              validationType={ValidationType.NUMBER}
              defaultValue={nationalNumber}
              width='250px'
              errorLabel={t('company_phone_invalid')}
              inputType={InputType.NUMBER}
              maxLen='16'
              disabled={isLoading}
              dataTestId='userPhone'
            />
          </div>
        </div>
        <div className='flex flex-row pt-1 items-center'>
          <div>
            <CheckBox
              onChange={(value: boolean) => handleTOSCheckboxToggle(value)}
              dataTestId='termAndService'
            />
          </div>
          <div className='text-sm text-black font-normal'>
            <Trans i18nKey='terms_of_service_register_msg_2' className=''>
              <a
                target='_blank'
                href='https://chargelab.co/driver-and-site-host-terms#site-host'
                rel='noreferrer'
                style={{
                  color: theme.navigationSelectedColor,
                }}
                className='text-sm font-semibold'
              >
                This text will be replaced by actual link text during
                translation
              </a>
              <a
                target='_blank'
                href='https://chargelab.co/driver-and-site-host-terms#privacy-policy'
                rel='noreferrer'
                style={{
                  color: theme.navigationSelectedColor,
                }}
                className='text-sm font-semibold'
              >
                This text will be replaced by actual link text during
                translation
              </a>
            </Trans>
          </div>
        </div>
      </div>

      <div className='w-full h-[48px]'>
        <Button
          label={t('submit')}
          type={ButtonType.PRIMARY}
          disabled={!onButtonValidation()}
          onClick={handleRegisterSubmit}
          size={ButtonSize.MEDIUM_FULL}
          dataTestId='registerUser'
        />
      </div>
      <div className='mt-[16px]'>
        <Link to={`/login${location.search || ''}`}>
          <span
            className='text-sm font-medium underline'
            style={{
              color: theme.navigationSelectedColor,
            }}
          >
            {t('login_login')}
          </span>
        </Link>
      </div>
    </div>
  );

  return (
    <div className='w-full'>
      {isRegisterSuccess ? renderRegisterDone() : renderRegister()}
    </div>
  );
});
