import { identity, pickBy } from 'lodash';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { NUMBER } from '../../../constant/Number.constant';
import { useFetchOcppMessagesQuery } from '../../../services/ocpp-messages.api';
import {
  formatDateTime,
  formatIso,
  formatTimeZoneDate,
  getLastFifteenMin,
} from '../../../utils/Date.Util';
import { copyToClipboard } from '../../../utils/copyToClipboard.util';
import {
  ColorType,
  CustomDatePicker,
  Grid,
  Icon,
  Label,
  LabelType,
  Pill,
} from '../../_ui';
import InputSearch from '../../_ui/InputSearch/InputSearch.component';
import Message from './Message.component';
import { searchOptions } from './mockData';

import { Refresh } from '../../../assets/icons';
import { IconSize } from '../../../constant/IconSize.constant';
import { DATE_RANGE } from '../../_ui/date-picker/Custom-Date-Picker.component';
import NoChargerMessage from './NoChargerMessage.component';
import { getRangeByOption } from './utils';

interface IProps {
  chargerId: string | undefined;
  chargerTimeZone: string;
  setMessagesFilter: Function;
  companyId: string;
}

interface IRange {
  option: string;
  date: Date[];
}
const ChargerMessage = ({
  chargerId,
  chargerTimeZone,
  setMessagesFilter,
  companyId,
}: IProps) => {
  const { t } = useTranslation();
  const [messageClicked, setMessageClicked] = useState('');
  const [currentPage, setCurrentPage] = useState(NUMBER.ONE);
  const [searches, setSearches] = useState<Array<any>>([]);
  const [lastUpdated, setLastUpdate] = useState(new Date());
  const [dateRange, setDateRange] = useState<IRange>({
    option: DATE_RANGE.LAST_FIFTEEN_MIN,
    date: getLastFifteenMin(),
  });

  const [selectedTime, currentTime] = useMemo(
    () => dateRange.date,
    [dateRange],
  );

  const MESSAGES_PER_ROW = NUMBER.FIFTY;
  const offsetIndex = (currentPage - 1) * MESSAGES_PER_ROW;

  const dataObj = useMemo(() => {
    const qArr: Array<string> = [];

    searches.forEach((item) => {
      qArr.push(`"${item.label}"`);
    });

    const obj = {
      chargerId,
      'filter[receivedTimeUtc][gte]': formatIso(selectedTime),
      'filter[receivedTimeUtc][lte]': formatIso(currentTime),
      sort: '-receivedTimeUtc',
      limit: MESSAGES_PER_ROW,
      offset: offsetIndex,
      lastUpdated,
      q: qArr.join(',') ? qArr.join(',') : undefined,
      companyId,
    };

    return pickBy(obj, identity);
  }, [
    selectedTime,
    currentTime,
    offsetIndex,
    searches,
    lastUpdated,
    MESSAGES_PER_ROW,
    chargerId,
  ]);

  const {
    data: messages,
    isLoading,
    isFetching,
  } = useFetchOcppMessagesQuery(dataObj);

  const isLoadingMessage = isLoading || isFetching;

  const refreshData = () => {
    if (dateRange.option !== DATE_RANGE.CUSTOM_RANGE) {
      const dateData = getRangeByOption(dateRange.option);
      if (dateData) {
        setDateRange({
          option: dateRange.option,
          date: dateData,
        });
      }
    }
    setCurrentPage(NUMBER.ONE);
    setLastUpdate(new Date());
  };

  const {
    LAST_FIFTEEN_MIN,
    LAST_ONE_HOUR,
    LAST_ONE_DAY,
    LAST_WEEK,
    CUSTOM_RANGE,
  } = DATE_RANGE;

  const copyMessage = (row: any) => {
    const selection = window?.getSelection()?.toString();
    if (Number(selection?.length) <= 0) {
      if (row.message) {
        copyToClipboard(row.message, setMessageClicked);
      }
    }
  };

  const columnsSettings = [
    {
      key: 'receivedTimeUtc',
      title: `${t('time')}`,
      secondaryTitle: `(${t('local')})`,
      component: (row: any) => {
        return (
          <>
            <Label
              text={formatTimeZoneDate(
                row.receivedTimeUtc,
                'h:mm:ss.ms a',
                chargerTimeZone,
              )}
              type={LabelType.BODY4}
              color={ColorType.BLACK}
              isLoading={isLoadingMessage}
              skeletonHeight='h-5'
              skeletonWidth='w-24'
              className='w-[max-content] pr-3'
            />
            <Label
              text={formatTimeZoneDate(
                row.receivedTimeUtc,
                'LLL d, yyyy',
                chargerTimeZone,
              )}
              type={LabelType.BODY4}
              color={ColorType.GREY5}
              skeletonHeight='h-5'
              skeletonWidth='w-full'
            />
          </>
        );
      },
    },
    {
      key: 'messageType',
      title: t('type'),
      component: (row: any) => {
        return (
          <Label
            text={row.messageType === 'ocpp1.6-incoming' ? t('in') : t('out')}
            type={LabelType.BODY4}
            color={ColorType.BLACK}
            isLoading={isLoadingMessage}
            skeletonHeight='h-5'
            skeletonWidth='w-full'
          />
        );
      },
    },
    {
      key: 'message',
      title: t('message'),
      component: (row: any) => (
        <Message
          messageClicked={messageClicked}
          session={row}
          searches={searches}
          isLoading={isLoadingMessage}
        />
      ),
    },
  ];

  const { entities, totalCount } = messages || {};

  useEffect(() => {
    setMessagesFilter({
      count: totalCount,
      filters: dataObj,
    });
  }, [totalCount, dataObj, setMessagesFilter]);

  const renderRecentSessionGrid = () => {
    if (!isLoadingMessage && !entities?.length) {
      return <NoChargerMessage />;
    }
    return (
      <div className='mt-2.5'>
        <Grid
          onRowClick={copyMessage}
          pageIndex={currentPage}
          loadPage={(page) => setCurrentPage(page)}
          local={false}
          columns={columnsSettings}
          data={entities}
          totalPage={Math.ceil(totalCount / NUMBER.FIFTY)}
          primaryKey='id'
          isLoading={isLoadingMessage}
          skeletonRowCount={NUMBER.TEN}
          hasRowBottomBorder
          scrollTableClass='overflow-hidden overflow-y-scroll'
          verticalAlign='align-baseline'
        />
      </div>
    );
  };

  const handleFilterItemClick = (item: any) => {
    setCurrentPage(NUMBER.ONE);
    setLastUpdate(new Date());
    setSearches([...searches].filter((i) => i.label !== item.label));
  };

  const handleClearAllClick = () => {
    setLastUpdate(new Date());
    setSearches([]);
  };

  const handleSelect = (item: any) => {
    // return if no-option selected
    if (typeof item !== 'object') return;

    const [filteredSearches] = searches.filter(
      (search) => search.label === item.label,
    );

    let arr: any[] = searches;

    if (filteredSearches) {
      arr = searches.filter((search) => search.label !== item.label);
    } else if (searches?.length < NUMBER.SIX) {
      arr = [...searches, item];
    }
    setLastUpdate(new Date());
    setCurrentPage(NUMBER.ONE);
    setSearches(arr);
  };

  const countText = () => {
    if (searches?.length || entities?.length) {
      let number = '0';
      const total = totalCount > 0 ? totalCount : '0';
      if (entities?.length) {
        number = `${offsetIndex + 1}-${offsetIndex + entities.length}`;
      }
      return t('showing_total_messages', {
        number: `<span class="font-medium">${number}</span>`,
        total: `<span class="font-medium">${total}</span>`,
      });
    }
    return null;
  };

  const selectDate = (option: string, date: Date[]) => {
    setCurrentPage(NUMBER.ONE);
    setLastUpdate(new Date());
    setDateRange({
      option,
      date,
    });
  };

  return (
    <div>
      <div className='flex justify-between mt-4'>
        <CustomDatePicker
          format='MMM d,yyyy'
          onChange={selectDate}
          optionList={[
            LAST_FIFTEEN_MIN,
            LAST_ONE_HOUR,
            LAST_ONE_DAY,
            LAST_WEEK,
            CUSTOM_RANGE,
          ]}
          showHighligtHeader={false}
          selectedOption={dateRange.option}
          includeDays={NUMBER.SEVEN}
        />
        <div className='w-[50%]'>
          <InputSearch
            width='100%'
            options={searchOptions}
            onSelect={handleSelect}
            placeholder={t(
              searches.length === NUMBER.SIX
                ? 'max_searches_reached'
                : 'search_charger_messages',
            )}
            contentDivWidth='w-[277px]'
            disabled={searches.length >= NUMBER.SIX}
            inputId='charger-message'
          />
        </div>
      </div>

      {searches?.length > 0 && (
        <div className='flex flex-row gap-2 mt-8 flex-wrap'>
          {searches.map((item) => {
            return (
              <Pill
                key={item.label}
                label={`"${item.label}"`}
                hasCloseButton
                onClick={() => handleFilterItemClick(item)}
                translationOn
              />
            );
          })}
          <button
            type='button'
            className='text-left'
            onClick={handleClearAllClick}
          >
            <Label
              type={LabelType.LABEL_S_MEDIUM}
              color={ColorType.BRAND2}
              text={t('session_clear_all')}
            />
          </button>
        </div>
      )}
      <div className='mt-3'>
        <div className='flex justify-between w-full'>
          <Label
            htmlText={countText()}
            isLoading={isLoadingMessage}
            type={LabelType.BODY3}
            color={ColorType.GREY6}
            skeletonHeight='h-[0px]'
          />
          <div className='flex items-center'>
            <Icon
              className='mr-[8px]'
              src={Refresh}
              onClick={refreshData}
              size={IconSize.SIZE_20x20}
            />
            <Label
              text={
                isLoadingMessage
                  ? t('updating_messages')
                  : t('last_updated', {
                      time: formatDateTime(lastUpdated, 'h:mm:ss a'),
                    })
              }
              type={LabelType.BODY3}
              color={ColorType.GREY6}
            />
          </div>
        </div>
        {renderRecentSessionGrid()}
      </div>
    </div>
  );
};

export default ChargerMessage;
