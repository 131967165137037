import { SCOPE_TYPE } from '../../services/utils';

export enum Category {
  CHARGERS = 'CHARGERS',
  SESSIONS = 'SESSIONS',
  FINANCIAL_PROGRAMS = 'FINANCIAL PROGRAMS',
}

export enum ReportType {
  FULL_SESSION = 'FULL SESSION',
  INTERVALS = 'INTERVALS',
  CHARGER_STATS = 'CHARGER_STATS',
  NYSERDA_ENERGY_USE = 'NYSERDA_ENERGY_USE',
  NYSERDA_STATUS_HISTORY = 'NYSERDA_STATUS_HISTORY',
}

export interface IRange {
  option: string;
  date: Date[];
}

export interface Reports {
  fromSessionCompletedDate?: Date;
  toSessionCompletedDate?: Date;
  chargerIds?: Array<string>;
  companyIds?: Array<string>;
  report?: string;
  months: Array<Date>;
}

export interface GenerateReportSyncRequest {
  scope: SCOPE_TYPE;
  reportInfo: Reports;
}

export interface GenerateStatisticsReportAsyncRequest {
  scope: SCOPE_TYPE;
  companyIds: string[]; // Setting this to ['all'] will properly construct a request for all companies
  requestedAt: Date;
  months: Array<Date>;
}

export interface GenerateSessionsReportAsyncRequest {
  scope: SCOPE_TYPE;
  companyIds: string[]; // Setting this to ['all'] will properly construct a request for all companies
  requestedAt: Date;
  fromSessionCompletedDate: Date;
  toSessionCompletedDate: Date;
}

export const FSRCColumn1 = [
  'session_id',
  'session_start',
  'session_end',
  'session_start_local',
  'session_end_local',
  'local_time_zone',
  'session_duration_min',
  'session_idle_duration',
  'session_energy_provided',
  'session_peak_power',
  'session_avg_power',
  'charger_device_id',
];

export const FSRCColumn2 = [
  'charger_display_id',
  'charger_serial_number',
  'connector_id',
  'connector_type',
  'charger_max_power',
  'location_id',
  'location_name',
  'location_street_address',
  'report_location_city',
  'report_location_state',
  'report_location_country',
  'location_zip_postal',
];

export const FSRCColumn3 = [
  'company',
  'company_id',
  'driver_id',
  'vehicle_name',
  'vehicle_vin',
  'vehicle_make',
  'vehicle_model',
  'rfid_number',
  'auth_type',
  'payment_collected',
  'currency_used',
];

export const INRCColumn1 = [
  'session_id',
  'session_start',
  'session_end',
  'session_start_local',
  'session_end_local',
  'local_time_zone',
  'session_duration_min',
  'session_idle_duration',
  'session_energy_provided',
  'session_peak_power',
  'session_avg_power',
];

export const INRCColumn2 = [
  'interval_id',
  'interval_start',
  'interval_end',
  'interval_start_local',
  'interval_end_local',
  'interval_idle_duration',
  'interval_energy_provided',
  'rolling_peak_power',
  'rolling_avg_power',
  'charger_device_id',
  'charger_display_id',
];

export const INRCColumn3 = [
  'charger_serial_number',
  'connector_id',
  'connector_type',
  'charger_max_power',
  'location_id',
  'location_name',
  'location_street_address',
  'report_location_city',
  'report_location_state',
  'report_location_country',
  'location_zip_postal',
];

export const INRCColumn4 = [
  'company',
  'company_id',
  'driver_id',
  'vehicle_name',
  'vehicle_vin',
  'vehicle_make',
  'vehicle_model',
  'rfid_number',
  'payment_collected',
  'currency_used',
];

export const CSRCColumn1 = [
  'charger_device_id',
  'charger_display_id',
  'charger_serial_number',
  'location_id',
  'location_name',
  'location_street_address',
  'report_location_city',
  'report_location_state',
  'report_location_country',
  'location_zip_postal',
  'company',
];

export const CSRCColumn2 = [
  'company_id',
  'local_time_zone',
  'location_geo',
  'summary_period_start',
  'summary_period_end',
  'number_of_sessions',
  'number_of_unique_drivers',
  'total_charging_time',
  'total_energy_provided',
  'payment_collected',
  'currency_used',
];

export const NYSERDAStatucColumn = [
  'evse_serial_number',
  'evse_status',
  'effective_status_date',
  'effective_status_time',
];

export const NYSERDAEnergyUseColumn1 = [
  'evse_serial_number',
  'session_start_date',
  'nyserda_session_start_time',
  'session_end_date',
  'nyserda_session_end_time',
  'charging_start_date',
  'charging_start_time',
];

export const NYSERDAEnergyUseColumn2 = [
  'charging_end_date',
  'charging_end_time',
  'session_duration',
  'charging_duration',
  'nyserda_total_energy_provided',
  'peak_power',
];
