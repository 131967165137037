/* eslint-disable import/no-duplicates */
import {
  addDays as addDaysfns,
  addMonths as addMonthsfns,
  differenceInDays as differenceInDaysfns,
  differenceInMinutes,
  format,
  formatISO,
  getMonth,
  getTime as getTimefns,
  getYear,
  isMatch,
  isValid,
  parse,
  parseISO,
  subDays,
  subHours,
  subMinutes,
  subMonths,
  toDate,
} from 'date-fns';
import { format as formatTZ, utcToZonedTime } from 'date-fns-tz';
import { enUS, es, fr } from 'date-fns/locale';

export const getLocale = () => {
  const locale = localStorage.getItem('language');
  switch (locale) {
    case 'enUS':
      return { locale: enUS };
    case 'fr':
      return { locale: fr };
    case 'es':
      return { locale: es };
    default:
      return { locale: enUS };
  }
};

export const formatDate = (date: Date, dateFormat: string = 'LLL dd, yyyy') => {
  if (isValid(date)) {
    return format(date, dateFormat || 'LLL dd, yyyy', getLocale());
  }

  return '';
};

export const formatTime = (date: Date, dateFormat: string = 'HH:mm') => {
  if (isValid(date)) {
    return format(date, dateFormat, getLocale());
  }

  return '';
};

export const isDateValid = (date: Date) => {
  return isValid(date);
};

export const formatTimeZoneDate = (
  utcDate: string,
  dataFormat: string,
  timeZone: string,
) => {
  if (!utcDate || !dataFormat || !timeZone) return '';

  const date = new Date(`${utcDate} UTC`);
  if (!isValid(date)) {
    return '';
  }
  const zonedDate = utcToZonedTime(date, timeZone);
  return formatTZ(zonedDate, dataFormat, { timeZone });
};

export const formatDateTime = (
  date: Date,
  dateFormat: string = 'LLL dd, yyyy HH:mm a',
) => {
  if (isValid(date)) {
    return format(date, dateFormat, getLocale());
  }

  return '';
};

export const addDays = (date: Date | number, days: number) =>
  addDaysfns(date, days);
export const addMonths = (date: Date | number, amount: number) =>
  addMonthsfns(date, amount);
export const differenceInDays = (
  startDate: Date | number,
  endDate: Date | number,
) => differenceInDaysfns(startDate, endDate);

export const getLastMonth = (date: Date = new Date()) => {
  const startDate = subMonths(date, 1);
  return [startDate, date];
};

export const getLastWeek = (date: Date = new Date()) => {
  const startDate = subDays(date, 7);
  return [startDate, date];
};

export const getLastFifteenMin = (date: Date = new Date()) => {
  const fifteenMinutesAgo = subMinutes(date, 15);
  return [fifteenMinutesAgo, date];
};

export const getLastOneHrs = (date: Date = new Date()) => {
  const oneHourAgo = subHours(date, 1);
  return [oneHourAgo, date];
};

export const getLastOneDay = (date: Date = new Date()) => {
  const twentyFourHoursAgo = subDays(date, 1);
  return [twentyFourHoursAgo, date];
};

export const getShortMonth = (date: Date) => {
  if (!isValid(date)) {
    return formatTime(new Date(`${date}-10`), 'LLL');
  }

  return formatTime(date, 'LLL');
};

export const formatIso = (date: Date) => formatISO(date);
export const convertToDate = (date: any) => parseISO(date);

export const getDifferenceInMinutes = (
  laterDate: Date,
  earlierDate: Date,
  options?: {},
) => {
  if (isValid(laterDate) && isValid(earlierDate)) {
    return differenceInMinutes(laterDate, earlierDate, options);
  }
  return '';
};

export const formatDateRange = (dates: (Date | null)[], locale = 'enUS') => {
  if (
    !dates ||
    dates.length < 2 ||
    (dates[0] && dates[1] && dates[0] >= dates[1])
  ) {
    return '';
  }

  if (dates[0] && dates[1] && getYear(dates[0]) === getYear(dates[1])) {
    if (getMonth(dates[0]) === getMonth(dates[1])) {
      return `${format(dates[0], 'LLL dd', getLocale())} - ${format(
        dates[1],
        'dd, yyyy',
        getLocale(),
      )}`;
    }
    return `${format(dates[0], 'LLL dd', getLocale())} - ${format(
      dates[1],
      'LLL dd, yyyy',
      getLocale(),
    )}`;
  }
  return `${dates[0] ? format(dates[0], 'LLL dd, yyyy', getLocale()) : ''} - ${
    dates[1] ? format(dates[1], 'LLL dd, yyyy', getLocale()) : ''
  }`;
};

export const parseDate = (
  dateString: string,
  formatString: string = 'MM/dd/yyyy',
  referenceDate: number | Date = new Date(),
) => {
  return parse(dateString, formatString, referenceDate);
};

export const isValidDate = (
  dateString: string,
  formatString: string = 'MM/dd/yyyy',
) => {
  const dateRegex = /^\d{1,2}\/\d{1,2}\/\d{4}$/;
  if (!dateRegex.test(dateString)) {
    return false;
  }
  return isMatch(dateString, formatString);
};

export const convertDateToString = (date: Date | number) => {
  return getTimefns(date).toString();
};

export const convertMilliSecondToDate = (millisecond: string | {} | null) => {
  return toDate(Number(millisecond));
};

export const formatDateString = (date: string, dateFormat: string) => {
  try {
    return format(convertToDate(date), dateFormat, getLocale());
  } catch (e) {
    return '';
  }
};

export const getISO8601TimezoneOffset = (date: Date) => {
  const offset = date.getTimezoneOffset();
  const absoluteOffset = Math.abs(offset);
  const hours = String(Math.floor(absoluteOffset / 60)).padStart(2, '0');
  const minutes = String(absoluteOffset % 60).padStart(2, '0');
  const sign = offset <= 0 ? '+' : '-';
  return `${sign}${hours}:${minutes}`;
};
