import _ from 'lodash';
import { useMemo } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { StringParam, useQueryParam } from 'use-query-params';
import {
  ChargerEV,
  ErrorWarningtraiangle,
  More,
  breaker,
} from '../../assets/icons';
import { IconSize } from '../../constant/IconSize.constant';
import {
  CHARGER_STATUS,
  PM_CHARGER_STATUS,
  PM_ERROR_CODE,
} from '../../constant/Text.constant';
import { USER_ROLE, useAuth } from '../../hooks';
import { useAllowedFeatures } from '../../hooks/useFeaturePersonalization';
import {
  useDeleteChargerLoadMutation,
  usePausePowerManagementMutation,
  useResumePowerManagementMutation,
} from '../../services/pm.api';
import { SCOPE_TYPE } from '../../services/utils';
import {
  Breaker,
  Charger as ChargerObj,
  Port,
} from '../../stores/types/pm.interface';
import { formatDateString } from '../../utils/Date.Util';
import {
  ButtonSize,
  ButtonType,
  ColorType,
  Icon,
  Label,
  LabelType,
  MODAL_TYPES,
  Menu,
  MenuItem,
  useGlobalModalContext,
} from '../_ui';
import {
  Tooltip,
  TooltipContent,
  TooltipTrigger,
} from '../_ui/Tooltip.component';
import { ChargerDetailsModal } from './ChargerDetailsModal';
import { Car } from './LocationDetailsHeader/Car';
import { Phase } from './LocationDetailsHeader/Phase';
import PowerManagementWarning from './Warnings/PowerManagementWarning';
import { checkIfLLWiring } from './utils';

interface Props {
  data: { chargerData: ChargerObj; breakerData: Breaker };
  isLocationWithPM?: boolean;
  index?: number;
  isSplitPhase?: boolean;
  location: any;
  editLoad: () => void;
  showWarning: Function;
}

export const Charger = ({
  data,
  isLocationWithPM = false,
  index,
  isSplitPhase = false,
  location,
  editLoad,
  showWarning,
}: Props) => {
  const auth = useAuth();
  const { t } = useTranslation();
  const { showModal } = useGlobalModalContext();
  const [companyId] = useQueryParam('companyId', StringParam);
  const [deleteChargerLoad] = useDeleteChargerLoadMutation();
  const [triggerResumePowerManagement] = useResumePowerManagementMutation();
  const { allowPowermanEdit } = useAllowedFeatures();

  const activePort = (ports: Port[]) => {
    const activePorts = ports.filter((port: Port) => port.charging === true);
    return activePorts.length;
  };

  const getChargerFromLocation = useMemo(() => {
    const foundCharger = location.chargers?.find(
      (c: any) => c.id === data.chargerData.deviceId,
    );
    return foundCharger;
  }, [location]);

  const handleViewChargerDetailsBtn = (ChargerModalData: {
    charger: any;
    breaker: Breaker;
    isSplitPhase: boolean;
  }) => {
    showModal(MODAL_TYPES.INFO_MODAL, {
      title: t('load_modal_title'),
      width: '540px',
      height: 'max-content',
      shouldCloseOnOverlayClick: false,
      onRenderBody: () => <ChargerDetailsModal data={ChargerModalData} />,
    });
  };

  const handleDeleteChargerLoad = () => {
    showModal(MODAL_TYPES.ALERT_MODAL, {
      title: `${t('pm_delete_load_menu')}?`,
      width: '400px',
      height: 'max-content',
      message: t('pm_delete_load_warning_2'),
      messageHeader: t('pm_delete_load_warning_1'),
      messageHeaderType: LabelType.LABEL_S,
      messageHeaderColor: ColorType.GREY6,
      iconColor: ColorType.NEGATIVE1,
      buttons: [
        {
          label: t('cancel'),
          type: ButtonType.TERTIARY,
          size: ButtonSize.SMALL,
        },
        {
          label: t('delete'),
          type: ButtonType.DESTRUCTIVE,
          size: ButtonSize.SMALL,
          onClick: () => {
            deleteChargerLoad({
              breakerId: data.breakerData.id,
              deviceId: data.chargerData.deviceId,
              scope: SCOPE_TYPE.COMPANY,
              companyId: companyId || '',
            });
          },
          dataTestId: 'deleteConfirm',
        },
      ],
    });
  };

  const pauseSuccessAlert = () => {
    showModal(MODAL_TYPES.SUCCESS_MODAL, {
      title: t('pm_alert_success_title'),
      width: '400px',
      height: 'max-content',
      onRenderBody: () => (
        <div className='flex flex-col gap-4'>
          <Label
            text={t('pm_alert_success_content_1')}
            type={LabelType.BODY3}
            color={ColorType.GREY6}
            className='text-center'
          />
          <Label
            text={t('pm_alert_success_content_2')}
            type={LabelType.BODY3}
            color={ColorType.GREY6}
            className='text-center'
          />
        </div>
      ),
      shouldCloseOnOverlayClick: false,
    });
  };

  const handlePauseUnpause = () => {
    const actionParams = {
      deviceId: data.chargerData.deviceId,
      scope: SCOPE_TYPE.COMPANY,
      companyId: companyId || '',
    };

    if (data.chargerData.reservePowerWhenOffline) {
      showModal(MODAL_TYPES.ALERT_MODAL, {
        title: `${t('pause_power_management_modal_title')}?`,
        width: '400px',
        height: 'max-content',
        message: t('pause_power_management_modal_content_2'),
        messageHeader: t('pause_power_management_modal_content_1'),
        messageHeaderType: LabelType.BODY3,
        messageHeaderColor: ColorType.GREY6,
        icon: ErrorWarningtraiangle,
        iconColor: ColorType.NEGATIVE1,
        mutationHook: usePausePowerManagementMutation,
        mutationParams: { ...actionParams, reservePowerWhenOffline: false },
        buttons: [
          {
            label: t('cancel'),
            type: ButtonType.TERTIARY,
            size: ButtonSize.SMALL,
          },
          {
            label: t('pause_power_management'),
            type: ButtonType.DESTRUCTIVE,
            size: ButtonSize.SMALL,
            isSubmit: true,
          },
        ],
        onClose: (resultQuery: any) => {
          if (resultQuery?.isSuccess) {
            setTimeout(() => pauseSuccessAlert(), 0);
          }
        },
        shouldCloseOnOverlayClick: false,
      });
    } else {
      triggerResumePowerManagement({
        ...actionParams,
        reservePowerWhenOffline: true,
      });
    }
  };

  // Sum up allocatedAmperage for all of the ports of the charger
  const totalAllocatedAmperage = (ports: Port[]) => {
    const total = ports.reduce((sum, port) => sum + port.allocatedAmperage, 0);
    return total.toFixed(1);
  };

  const getPortErrors = useMemo(() => {
    const chargerPortErrors = _.uniqBy(data.chargerData.ports, 'code');
    const errors = chargerPortErrors.reduce((errComponents, port) => {
      port.errors?.forEach((portErr: any) => {
        const errorCode: string = portErr?.code || '';
        if (PM_ERROR_CODE[errorCode]) {
          let tooltipDateTime: any = '';
          if (errorCode === 'ERR-PM-1018') {
            const lastOccurrence = portErr?.errors?.filter(
              (err: any) => err?.field === 'lastOccurrence',
            );
            if (lastOccurrence.length) {
              tooltipDateTime = (
                <Trans
                  i18nKey='pm_power_profile_error_datetime'
                  values={{
                    datetime: formatDateString(
                      lastOccurrence[0]?.message?.replace(/\[.*\]/, ''),
                      'LLL d yyyy, h:mm a',
                    ),
                  }}
                />
              );
            }
          }
          errComponents.push(
            <PowerManagementWarning
              label={t(PM_ERROR_CODE[errorCode].label)}
              hoverTooltipContent={t(PM_ERROR_CODE[errorCode].hoverTooltip)}
              clickTooltipContent={t(PM_ERROR_CODE[errorCode].clickTooltip)}
              warningType={PM_ERROR_CODE[errorCode].type}
              tooltipDateTime={tooltipDateTime}
            />,
          );
        }
      });
      return errComponents;
    }, [] as any[]);

    return errors;
  }, [data.chargerData]);

  const getChargerErrors = useMemo(() => {
    const chargerErrors = _.uniqBy(data.chargerData?.errors || [], 'code');
    const errors = chargerErrors.reduce((errComponents, errObj: any) => {
      const errorCode = errObj?.code || '';
      if (PM_ERROR_CODE[errorCode]) {
        errComponents.push(
          <PowerManagementWarning
            label={t(PM_ERROR_CODE[errorCode].label)}
            hoverTooltipContent={t(PM_ERROR_CODE[errorCode].hoverTooltip)}
            clickTooltipContent={t(PM_ERROR_CODE[errorCode].clickTooltip)}
            warningType={PM_ERROR_CODE[errorCode].type}
          />,
        );
      }
      return errComponents;
    }, [] as any[]);

    return errors;
  }, [data.chargerData]);

  return (
    <div
      className={
        data.breakerData.dedicatedBreaker ? 'px-4 py-2 mb-2' : 'px-4 py-2'
      }
      style={
        data.breakerData.dedicatedBreaker
          ? {}
          : {
              borderBottom: '1px solid #D1D6DB',
              borderTop: index === 0 ? '1px solid #D1D6DB' : '',
            }
      }
    >
      <div className='flex justify-between items-center'>
        <div className='flex'>
          <div className='pr-2 pt-0.5'>
            <Icon src={ChargerEV} size={IconSize.SIZE_20x20} />
          </div>
          <div className={`flex ${isLocationWithPM ? 'flex-col' : 'flex-row'}`}>
            <div className='flex flex-row flex-wrap gap-2'>
              <Label
                text={getChargerFromLocation?.displayName}
                type={LabelType.LABEL_M_MEDIUM}
                color={ColorType.BLACK}
                dataTestId={getChargerFromLocation?.displayName}
              />
              {!data.chargerData.reservePowerWhenOffline &&
                data.chargerData.status === PM_CHARGER_STATUS.VALID && (
                  <PowerManagementWarning
                    label={
                      data.chargerData.chargerStatus?.toLocaleLowerCase() ===
                      CHARGER_STATUS.COMING_SOON
                        ? t('pm_pause_resume_pending_label')
                        : t('pm_pause_label_warning')
                    }
                    hoverTooltipContent={
                      data.chargerData.chargerStatus?.toLocaleLowerCase() ===
                      CHARGER_STATUS.COMING_SOON
                        ? t('pm_resume_coming_soon_tooltip')
                        : t('pm_resume_not_coming_soon_tooltip')
                    }
                    clickTooltipContent={
                      data.chargerData.chargerStatus?.toLocaleLowerCase() ===
                      CHARGER_STATUS.COMING_SOON
                        ? t('pm_resume_coming_soon_tooltip')
                        : t('pm_pause_label_content')
                    }
                  />
                )}
              {getChargerErrors}
              {getPortErrors}
            </div>
            {isLocationWithPM && (
              <div className='flex gap-x-4'>
                <div className='flex flex-row items-baseline'>
                  <Label
                    text={totalAllocatedAmperage(data.chargerData.ports)}
                    type={LabelType.LABEL_M_MEDIUM}
                    color={ColorType.ACCENT1}
                    style={{ paddingRight: '2px' }}
                  />
                  <Label
                    text='A'
                    type={LabelType.LABEL_XS}
                    color={ColorType.GREY5}
                    style={{ paddingRight: '8px' }}
                  />
                  <Label
                    text='/'
                    type={LabelType.LABEL_M_MEDIUM}
                    color={ColorType.GREY5}
                    style={{ paddingRight: '8px' }}
                  />
                  <Label
                    text={Math.ceil(data.chargerData.maxAmp)}
                    type={LabelType.LABEL_M_MEDIUM}
                    style={{ paddingRight: '2px' }}
                  />
                  <Label
                    text='A'
                    type={LabelType.LABEL_XS}
                    color={ColorType.GREY5}
                  />
                </div>
                <Car
                  activePort={activePort(data.chargerData.ports)}
                  totalPort={data.chargerData.ports.length}
                />
                {!checkIfLLWiring(
                  isSplitPhase,
                  data.chargerData.chargerWiring,
                ) && (
                  <Phase
                    value={data.chargerData.chargerWiring}
                    isElectricSupply={false}
                  />
                )}
                {data.breakerData.dedicatedBreaker && (
                  <div className='flex flex-row gap-x-1.5 items-center'>
                    <Icon src={breaker} size={IconSize.SIZE_20x20} />
                    <Label
                      text={`${data.breakerData.breakerRating} A dedicated breaker`}
                      type={LabelType.LABEL_XS}
                      color={ColorType.GREY5}
                    />
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
        {isLocationWithPM && (
          <Menu
            contentClassName='w-[230px] overflow-y-visible'
            placement='bottom-end'
            icon={More}
            dataTestId='menuWrapper'
          >
            <MenuItem
              label={t('load_popup_menu_label_1')}
              onClick={() =>
                handleViewChargerDetailsBtn({
                  charger: {
                    ...data.chargerData,
                    displayName: getChargerFromLocation?.displayName,
                    type: getChargerFromLocation?.type,
                    model: getChargerFromLocation?.model,
                  },
                  breaker: data.breakerData,
                  isSplitPhase,
                })
              }
            />
            {(data.chargerData.chargerStatus?.toLocaleLowerCase() ===
              CHARGER_STATUS.OFFLINE ||
              data.chargerData.chargerStatus?.toLocaleLowerCase() ===
                CHARGER_STATUS.COMING_SOON) &&
            data.chargerData.status === PM_CHARGER_STATUS.VALID ? (
              <MenuItem
                label={
                  !data.chargerData.reservePowerWhenOffline
                    ? t('resume_power_management')
                    : t('pause_power_management')
                }
                onClick={handlePauseUnpause}
                labelColor={
                  !data.chargerData.reservePowerWhenOffline
                    ? ColorType.BLACK
                    : ColorType.NEGATIVE1
                }
              />
            ) : (
              <Tooltip placement='left'>
                <TooltipTrigger className='w-full'>
                  <MenuItem
                    label={t('pause_power_management')}
                    onClick={() => {}}
                    disabled
                  />
                </TooltipTrigger>
                <TooltipContent>
                  <Label
                    text={t('pm_disabled_pause_menu_tooltip')}
                    type={LabelType.BODY3}
                    color={ColorType.WHITE}
                  />
                </TooltipContent>
              </Tooltip>
            )}
            {(auth.role === USER_ROLE.SUPPORT || allowPowermanEdit) && (
              <>
                <MenuItem
                  label={t('pm_edit_load_details')}
                  onClick={() => {
                    showWarning(location.id, (actionValue: boolean) => {
                      if (actionValue) {
                        editLoad();
                      }
                    });
                  }}
                />
                <MenuItem
                  label={t('pm_delete_load_menu')}
                  labelColor={ColorType.NEGATIVE1}
                  dataTestId='deleteLoad'
                  onClick={() => {
                    showWarning(location.id, (actionValue: boolean) => {
                      if (actionValue) {
                        handleDeleteChargerLoad();
                      }
                    });
                  }}
                />
              </>
            )}
          </Menu>
        )}
      </div>
    </div>
  );
};
