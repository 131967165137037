import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { StringParam, useQueryParam } from 'use-query-params';
import { NUMBER } from '../../constant/Number.constant';
import { Charger as ChargerObj, Location } from '../../stores/types';
import {
  Breaker,
  Charger,
  Circuit,
  DropdownOption,
  ParentBreakerOptions,
  UnmanagedLoad,
} from '../../stores/types/pm.interface';
import {
  Card,
  ColorType,
  Icon,
  Label,
  LabelType,
  MODAL_TYPES,
  Tab,
  Tabs,
  getHexColorByType,
  useGlobalModalContext,
} from '../_ui';
import { Breaker as BreakerComponent } from './Breaker';
import { Charger as ChargerComponent } from './Charger';
import { LocationCard } from './LocationCard';
import { Car } from './LocationDetailsHeader/Car';
import { Limit } from './LocationDetailsHeader/Limit';
import { Load } from './LocationDetailsHeader/Load';
import { Phase } from './LocationDetailsHeader/Phase';
import { Rating } from './LocationDetailsHeader/Rating';
import { Volt } from './LocationDetailsHeader/Volt';
import { NoPowerManaged } from './NoPowerManaged';
import NotPowerManagedChargers from './NotPowerManagedChargers/NotPowerManagedChargers';
import { UnmanagedLoad as UnmanagedLoadComponent } from './UnmanagedLoad';
import {
  BREAKER_LOAD_TYPES,
  ELECTRIC_SUPPLY,
  checkIfBreakerWarning,
  checkIfLocationWarning,
  getAllCircuitChargerIds,
  sortBreakerChargers,
  sortNotPowerManagedChargers,
  sortSubBreakers,
  sortUnmanagedLoads,
  defaultLoadConfig,
} from './utils';

import NonPowerManagedWarning from './Warnings/NonPowerManagedWarning';
import { IconSize } from '../../constant/IconSize.constant';
import { ChargerEV, breaker as breakerIcon } from '../../assets/icons';
import { LoadStepTwo } from './SubBreakerLoad/LoadStepTwo';

enum TABS {
  MAIN_BREAKER = 0,
  NO_POWER_MANAGED,
}
interface LocationWithPowerProps {
  data: [string, Circuit[]][];
  location: Location[];
  parentBreakers: ParentBreakerOptions;
  showWarning: Function;
}

interface ExpandedDataProps {
  circuits: Circuit[];
  isLocationExpanded: boolean;
  location: Location;
  showWarning: Function;
  parentBreakerOptions: DropdownOption[];
}

const ExpandedData = ({
  circuits,
  isLocationExpanded,
  location,
  showWarning,
  parentBreakerOptions,
}: ExpandedDataProps) => {
  const [selectedTab, setSelectedTab] = useState<number>(TABS.MAIN_BREAKER);
  const { showModal } = useGlobalModalContext();
  const [companyId] = useQueryParam('companyId', StringParam);
  const [wizardState, setWizardState] = useState<any>({
    loadData: [],
    activeIndex: 0,
  });
  const MAIN_BREAKER_COUNT_TO_SHORTEN_NAME = 4;
  const { t } = useTranslation();

  const powermanagedChargerIds = useMemo(
    () => getAllCircuitChargerIds(circuits),
    [circuits],
  );

  const notPowerManagedChargers: ChargerObj[] = useMemo(() => {
    const filteredChargers: ChargerObj[] = (location.chargers || [])?.filter(
      (charger: ChargerObj) => !powermanagedChargerIds.includes(charger.id),
    );
    return sortNotPowerManagedChargers(filteredChargers);
  }, [powermanagedChargerIds, location]);

  const handleEditLoad = (
    circuit: Circuit,
    breaker: Breaker,
    data: any,
    type: string,
  ) => {
    const parentBreakerIdName = {
      parentBreakerId: breaker.dedicatedBreaker
        ? breaker.parentBreakerId
        : breaker.id,
      name: breaker.name,
    };

    let dedicatedBreaker = null;

    if (breaker.dedicatedBreaker) {
      const { name, breakerRating, breakerSpace } = breaker;
      dedicatedBreaker = {
        parentBreakerId: parentBreakerIdName.parentBreakerId,
        name,
        breakerRating,
        breakerSpace,
      };
    }
    if (type === BREAKER_LOAD_TYPES.CHARGER) {
      setWizardState({
        ...wizardState,
        circuit,
        breaker,
        parentBreakerIdName,
        loadData: [
          {
            ...defaultLoadConfig,
            loadType: BREAKER_LOAD_TYPES.CHARGER,
            deviceId: data.deviceId,
            editChargerOption: {
              id: data.deviceId,
              label: data.displayName,
              selected: true,
            },
            ctdb: breaker.dedicatedBreaker,
            dedicatedBreaker,
            wiringPhase: data.chargerWiring,
            zeroAmpSupport: data.zeroAmpSupport,
            editId: data.deviceId,
          },
        ],
      });
    } else {
      setWizardState({
        ...wizardState,
        circuit,
        breaker,
        parentBreakerIdName,
        loadData: [
          {
            ...defaultLoadConfig,
            loadType: BREAKER_LOAD_TYPES.UNMANAGEDlOAD,
            ctdb: breaker.dedicatedBreaker,
            dedicatedBreaker,
            name: data.name,
            fixedAmperage: data.fixedAmperage,
            wiringPhase: data.wiringPhase,
            editId: data.id,
          },
        ],
      });
    }
  };

  useEffect(() => {
    if (wizardState.loadData.length > 0) {
      const { breaker, circuit } = wizardState;
      showModal(MODAL_TYPES.INFO_MODAL, {
        title: t('pm_edit_load_details'),
        width: '540px',
        height: 'max-content',
        shouldCloseOnOverlayClick: false,
        onRenderBody: () => (
          <LoadStepTwo
            data={{ breaker, circuit }}
            wizardState={wizardState}
            setWizardState={setWizardState}
            notPowerManagedChargers={notPowerManagedChargers}
            companyId={companyId}
            isEdit
          />
        ),
      });
    }
  }, [wizardState]);

  const renderUnmanagedLoad = (breaker: Breaker, circuit: Circuit) => {
    if (breaker.unmanagedLoads?.length === 0) return null;
    const unmanagedLoads = sortUnmanagedLoads(breaker.unmanagedLoads);
    return (
      <>
        {unmanagedLoads.map((data: UnmanagedLoad, index: number) => {
          return (
            <div
              key={data.id}
              className={breaker.dedicatedBreaker ? '' : 'pl-2 pr-6'}
            >
              <UnmanagedLoadComponent
                data={{ unmanagedLoadData: data, breakerData: breaker }}
                isSplitPhase={
                  ELECTRIC_SUPPLY.SPLIT_PHASE === circuit.electricalSupply
                }
                // Checking if there exist charger then don't display borderTop in order to avoid double borders
                index={breaker.chargers?.length === 0 ? index : -1}
                editLoad={() =>
                  handleEditLoad(
                    circuit,
                    breaker,
                    data,
                    BREAKER_LOAD_TYPES.UNMANAGEDlOAD,
                  )
                }
                showWarning={showWarning}
                locationId={location.id}
              />
            </div>
          );
        })}
      </>
    );
  };

  const renderCharger = (breaker: Breaker, circuit: Circuit) => {
    if (!breaker.chargers.length) return null;
    const chargers = sortBreakerChargers(breaker.chargers, location);

    return (
      <>
        {chargers.map((charger: Charger, index: number | undefined) => {
          return (
            <div
              key={charger.deviceId}
              className={breaker.dedicatedBreaker ? '' : 'pl-2 pr-6'}
            >
              <ChargerComponent
                key={charger.deviceId}
                data={{ chargerData: charger, breakerData: breaker }}
                isLocationWithPM
                index={index}
                isSplitPhase={
                  ELECTRIC_SUPPLY.SPLIT_PHASE === circuit.electricalSupply
                }
                location={location}
                editLoad={() =>
                  handleEditLoad(
                    circuit,
                    breaker,
                    charger,
                    BREAKER_LOAD_TYPES.CHARGER,
                  )
                }
                showWarning={showWarning}
              />
            </div>
          );
        })}
      </>
    );
  };

  const renderSubBreakers = (circuit: Circuit, breaker: Breaker) => {
    if (breaker.subBreakers.length) {
      return (
        <div>
          {sortSubBreakers(breaker.subBreakers)?.map(
            (ele: Breaker, index: any) => {
              return (
                <BreakerComponent
                  key={ele.id}
                  index={index}
                  data={{
                    breakerData: ele,
                    parentBreakerData: breaker,
                    circuitData: circuit,
                    parentBreakerOptions,
                  }}
                  style={{
                    borderRadius: '8px',
                    border: '1px solid #D1D6DB',
                    background: getHexColorByType(ColorType.WHITE),
                  }}
                  className={
                    ele.dedicatedBreaker ? 'p-0 my-2 ml-2 mr-6' : 'p-4 my-2'
                  }
                  isLocationExpanded={isLocationExpanded}
                  notPowerManagedChargers={notPowerManagedChargers}
                  showWarning={showWarning}
                  locationId={location.id}
                >
                  {/* This helps to display nested sub breakers within a sub breakers */}
                  {!!ele.subBreakers?.length && renderSubBreakers(circuit, ele)}
                  {renderCharger(ele, circuit)}
                  {renderUnmanagedLoad(ele, circuit)}
                </BreakerComponent>
              );
            },
          )}
        </div>
      );
    }
    return null;
  };

  const generateCircuitTabs = (circuit: Circuit, index: number) => {
    const name =
      circuit.mainBreakers && circuit.mainBreakers.length > 0
        ? circuit.name
        : '';

    // const isMainBreakerError = checkIfBreakerWarning(circuit.mainBreakers);

    const chargersLength = getAllCircuitChargerIds([circuit])?.length;
    return (
      <Tab key={circuit.id}>
        <div className='flex items-center justify-center'>
          {/* To be used in future release 2 */}
          {/* {isMainBreakerError && (
                <Icon className='mr-2' src={infoRed} height={20} width={20} />
              )} */}
          <Label
            className='flex-shrink text-ellipsis overflow-hidden whitespace-nowrap'
            text={(name || '')!}
            type={LabelType.LABEL_M}
            color={selectedTab === index ? ColorType.BLACK : ColorType.GREY3}
          />
          <div className='flex flex-row flex-shrink-0 items-center pl-2'>
            <Icon
              src={ChargerEV}
              size={IconSize.SIZE_20x20}
              color={selectedTab === index ? ColorType.GREY5 : ColorType.GREY3}
            />
            <Label
              text={chargersLength || 0}
              type={LabelType.LABEL_M_MEDIUM}
              color={selectedTab === index ? ColorType.GREY5 : ColorType.GREY3}
            />
          </div>
        </div>
      </Tab>
    );
  };

  const generateNotPowerManagedChargerTab = () => {
    return (
      <Tab key='not-power-managed'>
        <div className='flex items-center justify-center'>
          <Label
            className='flex-shrink text-ellipsis overflow-hidden whitespace-nowrap'
            text={t('not_power_mananged')}
            type={LabelType.LABEL_M}
            color={
              selectedTab === circuits?.length
                ? ColorType.BLACK
                : ColorType.GREY3
            }
          />
          <div className='flex flex-row flex-shrink-0 items-center pl-2'>
            <Icon
              src={ChargerEV}
              size={IconSize.SIZE_20x20}
              color={
                selectedTab === circuits?.length
                  ? ColorType.GREY5
                  : ColorType.GREY3
              }
            />
            <Label
              text={notPowerManagedChargers?.length || 0}
              type={LabelType.LABEL_M_MEDIUM}
              color={
                selectedTab === circuits?.length
                  ? ColorType.GREY5
                  : ColorType.GREY3
              }
            />
          </div>
        </div>
      </Tab>
    );
  };
  const combineTabs = () => {
    const tabs = [];
    circuits.map((circuit: Circuit, index: number) =>
      tabs.push(generateCircuitTabs(circuit, index)),
    );
    if (notPowerManagedChargers.length) {
      tabs.push(generateNotPowerManagedChargerTab());
    }
    return tabs;
  };

  // Design demands to not show breaker tabs when circuit has just one breaker containing all of the chargers at the location
  const checkIfSingleBreakerHasAllChargers = () => {
    return circuits.length === 1 && notPowerManagedChargers.length === 0;
  };
  return (
    <>
      {checkIfSingleBreakerHasAllChargers() ? (
        <div className='border-b border-grey3 border-solid' />
      ) : (
        <Tabs rootClass='w-full' tabClass='px-1' onTabChange={setSelectedTab}>
          {combineTabs()}
        </Tabs>
      )}
      {circuits.map((circuit: Circuit, index: number) => {
        return (
          <div
            key={circuit.id}
            style={{ display: selectedTab === index ? '' : 'none' }}
          >
            <BreakerComponent
              // eslint-disable-next-line react/no-array-index-key
              key={`${index}-${circuit.id}`}
              data={{
                breakerData: circuit.mainBreakers
                  ? circuit.mainBreakers[0]
                  : ({} as Breaker),
                circuitData: circuit,
                parentBreakerOptions,
              }}
              className='pt-4 pb-2'
              isLocationExpanded={isLocationExpanded}
              notPowerManagedChargers={notPowerManagedChargers}
              showWarning={showWarning}
              locationId={location.id}
            >
              {circuit.mainBreakers &&
                renderSubBreakers(circuit, circuit.mainBreakers[0])}
            </BreakerComponent>
            {circuit.mainBreakers &&
              renderCharger(circuit.mainBreakers[0], circuit)}
            {circuit.mainBreakers &&
              renderUnmanagedLoad(circuit.mainBreakers[0], circuit)}
          </div>
        );
      })}
      {selectedTab === circuits.length && (
        <NotPowerManagedChargers
          notPowerManagedChargers={notPowerManagedChargers}
        />
      )}
    </>
  );
};

export const LocationWithPower = ({
  data,
  location,
  showWarning,
  parentBreakers,
}: LocationWithPowerProps) => {
  const { t } = useTranslation();
  const [locationExpanded, setLocationExpanded] = useState(false);

  const getLocation = (locationId: string) => {
    return location.find((item) => item.id === locationId);
  };

  const LocationDetailsHeader = (circuits: Circuit[]) => {
    return circuits.map((circuit: Circuit) => {
      if (circuit.mainBreakers) {
        return (
          <div key={circuit.id} className='flex flex-row gap-x-2'>
            <Icon
              src={breakerIcon}
              size={IconSize.SIZE_20x20}
              className={`flex items-center ${
                circuits.length > 1 ? 'visible' : 'invisible'
              }`}
            />
            <div className='flex flex-row gap-x-4'>
              <Rating value={circuit.mainBreakers[0].breakerRating} />
              <Limit value={circuit.mainBreakers[0].continuousLoadLimit} />
              <Load value={circuit.mainBreakers[0].noOfLoads} />
              <Phase value={circuit.electricalSupply} isElectricSupply />
              <Volt value={circuit.lineVoltage} />
              {/* TODO: KS Check how we can show active port out of total? */}
              <Car
                activePort={circuit.mainBreakers[0].noOfActivePorts}
                totalPort={circuit.mainBreakers[0].noOfPorts}
              />
            </div>
          </div>
        );
      }
      return null;
    });
  };

  const LocationDetails = (circuit: [string, Circuit[]]) => {
    const [locationId, circuitData] = circuit;

    const locationData = getLocation(locationId);
    const powermanagedChargerIds = getAllCircuitChargerIds(circuitData);

    const notPowerManagedChargers: ChargerObj[] = (
      locationData?.chargers || []
    )?.filter(
      (charger: ChargerObj) => !powermanagedChargerIds.includes(charger.id),
    );

    const isWarningVisible =
      !!notPowerManagedChargers?.length && !!powermanagedChargerIds?.length;

    return (
      <div className='flex pt-1 pb-4'>
        <Label
          text={t('total_chargers_being_managed', {
            chargers: powermanagedChargerIds.length,
            totalChargers: locationData?.chargers?.length,
          })}
          type={LabelType.LABEL_M_MEDIUM}
          color={ColorType.GREY6}
          className='pr-2'
        />
        <NonPowerManagedWarning
          className=''
          show={isWarningVisible}
          message={t('non-power-managed-warning')}
        />
      </div>
    );
  };

  const handleLocationExpansion = (value: boolean) => {
    setLocationExpanded(value);
  };

  return (
    <div data-testid='locationContainer'>
      {data.length !== NUMBER.ZERO ? (
        data.map((value, key) => {
          const isLocationError = checkIfLocationWarning(value);
          const mainBreakerCount = value[1].length;
          return (
            <LocationCard
              index={key}
              key={value[0]}
              isError={isLocationError}
              cardTitle={getLocation(value[0])?.name || ''}
              LocationDetails={LocationDetails(value)}
              CollapsedHeaderDetails={LocationDetailsHeader(value[1])}
              locationCount={data.length}
              isPowerManaged
              handleLocationExpansion={handleLocationExpansion}
              locationId={String(value[0])}
              mainBreakerCount={mainBreakerCount}
              showWarning={showWarning}
            >
              <ExpandedData
                circuits={value[1]}
                isLocationExpanded={locationExpanded}
                location={getLocation(value[0]) || ({} as Location)}
                showWarning={showWarning}
                parentBreakerOptions={parentBreakers[String(value[0])]}
              />
            </LocationCard>
          );
        })
      ) : (
        <NoPowerManaged isWithPower isWithLocation />
      )}
    </div>
  );
};
