import _ from 'lodash';
import { Company } from '../stores/types';
import { PayterTerminal } from '../stores/types/payter.interface';
import { Api, API_KEYS } from './base.api';
import { downloadBlob, providesListTag, SCOPE_TYPE } from './utils';

export enum PayoutReportType {
  BY_CHARGER = 'MONTHLY_STATION',
  BY_USER = 'MONTHLY_RF',
  BY_SESSION = 'MONTHLY_CUSTOMER',
}

export interface PayoutReportOptions {
  year: number;
  month: string;
  reportType: PayoutReportType;
  domainName?: string;
  rfIds?: string;
  [key: string]: any;
}

const formatPayoutParams = (params: PayoutReportOptions) => {
  const result: Record<string, any> = {};
  Object.entries(params)?.forEach(([key, value]: [string, any]) => {
    switch (key) {
      case 'rfIds':
        if (value && !_.isEmpty(value)) {
          result.rfIds = value;
        }
        break;
      default:
        result[key] = value;
        break;
    }
  });
  return result;
};

export const payoutApi = Api.injectEndpoints({
  endpoints: (builder) => ({
    exportPayoutReportPDF: builder.query<any, any>({
      query: (params: PayoutReportOptions) => ({
        url: 'report/monthly/transaction/company/get',
        method: 'GET',
        params: { ...params, scope: SCOPE_TYPE.NONE },
        useLegacyBaseUrl: true,
        isPDF: true,
        responseHandler: (response) =>
          response
            .blob()
            .then(
              (blob) =>
                blob.size > 0 &&
                downloadBlob(
                  blob,
                  response.headers
                    .get('content-disposition')
                    ?.split('filename=')[1]
                    .split('.')[0] || 'Payout Report',
                  'pdf',
                ),
            ),
      }),
    }),
    exportPayoutReportUserTransactionPDF: builder.query<Blob, any>({
      query: (params: PayoutReportOptions) => ({
        url: 'report/monthly/customer/dash/get',
        params: formatPayoutParams({ ...params, scope: SCOPE_TYPE.NONE }),
        method: 'GET',
        useLegacyBaseUrl: true,
        isPDF: true,
        responseHandler: (response) =>
          response
            .blob()
            .then(
              (blob) =>
                blob.size > 0 &&
                downloadBlob(
                  blob,
                  response.headers
                    .get('content-disposition')
                    ?.split('filename=')[1]
                    .split('.')[0] || 'Payout Report',
                  'pdf',
                ),
            ),
      }),
    }),
    fetchUsersEmail: builder.query<string[], any>({
      query: (params: any) => ({
        url: 'clientHistory/rfIdByMonthAndYear',
        method: 'GET',
        params: { ...params, scope: SCOPE_TYPE.NONE },
        useLegacyBaseUrl: true,
      }),
    }),
    fetchTerminals: builder.query<PayterTerminal, any>({
      query: (params) => ({
        url: `/internal/core/v2/${API_KEYS.PAYMENT_TERMINALS_COMBINED}`,
        method: 'GET',
        params,
      }),
      providesTags: (result) =>
        providesListTag(
          result?.entities?.map((terminal) => ({ id: terminal.id })),
          API_KEYS.PAYMENT_TERMINALS_COMBINED,
        ),
    }),
    editTerminalsById: builder.mutation<PayterTerminal, any>({
      query: (params) => ({
        url: `/internal/core/v2/${API_KEYS.PAYMENT_TERMINALS}/${params.body.terminalId}`,
        method: 'PATCH',
        body: params.body,
        toast: params.toast,
      }),
      invalidatesTags: [API_KEYS.PAYMENT_TERMINALS_COMBINED],
    }),
    linkTerminals: builder.mutation<PayterTerminal, any>({
      query: (params) => ({
        url: `/internal/core/v2/${API_KEYS.PAYMENT_TERMINALS}`,
        method: 'POST',
        body: params.body,
        toast: params.toast,
      }),
      invalidatesTags: [API_KEYS.PAYMENT_TERMINALS_COMBINED],
    }),
    deleteTerminals: builder.mutation<PayterTerminal, any>({
      query: (params) => ({
        url: `/internal/core/v2/${API_KEYS.PAYMENT_TERMINALS}/${params.body.terminalId}`,
        method: 'DELETE',
        toast: params.toast,
      }),
      invalidatesTags: [API_KEYS.PAYMENT_TERMINALS_COMBINED],
    }),

    updatePayterIdentifier: builder.mutation<Company, any>({
      query: (payload) => ({
        url: `/internal/core/v2/${API_KEYS.COMPANIES}/${payload.body.id}`,
        body: payload.body,
        method: 'PATCH',
        toast: payload.toast,
      }),
      invalidatesTags: (result, error, arg) => [
        { type: API_KEYS.COMPANIES, id: 'LIST' },
        { type: API_KEYS.COMPANIES, id: result?.id },
        { type: API_KEYS.PAYMENT_TERMINALS_COMBINED },
      ],
    }),
  }),
});

export const {
  useExportPayoutReportPDFQuery,
  useLazyExportPayoutReportPDFQuery,
  useLazyExportPayoutReportUserTransactionPDFQuery,
  useLazyFetchUsersEmailQuery,
  useFetchTerminalsQuery,
  useEditTerminalsByIdMutation,
  useLinkTerminalsMutation,
  useUpdatePayterIdentifierMutation,
  useDeleteTerminalsMutation,
} = payoutApi;
