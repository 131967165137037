/* eslint-disable react/jsx-one-expression-per-line */
import { useCallback, useEffect, useMemo, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { ColorType, Divider, Menu, MenuItem, Select, SelectItem } from '.';
import {
  BellNotification,
  Document,
  ExternalLink,
  LanguageTranslate,
  MyAccount,
  SignoutLogout,
  menu,
  notification as notificationIcon,
} from '../../assets/icons';
import { useAuth } from '../../hooks';
import {
  RoutePath,
  getRouteIcon,
  getRouteTitle,
  routes,
  sideBarMenus,
} from '../../routes';
import { useLazyFetchNotificationsQuery } from '../../services/notification.api';
import { Icon } from './Icon.component';
import { Label, LabelType } from './Label.component';
import {
  MODAL_TYPES,
  useGlobalModalContext,
} from './modal/GlobalModal.component';

import { IconSize } from '../../constant/IconSize.constant';
import { useCompany } from '../../hooks/useCompany';
import { useNavigateWithSearchParam } from '../../hooks/useNavigateWithSearchParam';
import {
  useClickAnalyticsMutation,
  useLogoutAnalyticsMutation,
} from '../../services/google.api';
import { useFetchDelegationInvitesQuery } from '../../services/invite.api';
import { getCurrentTheme } from '../../stores/selectors/theme.selector';
import { GA_LABEL } from '../../utils/GoogleAnalytics';
import { CompanySelect } from './CompanySelect.component';
import {
  getIsItemSelected,
  getPathByIndex,
  getSelectedMenuIndex,
} from './Sidebar.util';
import { Tooltip, TooltipContent, TooltipTrigger } from './Tooltip.component';

export const AppHeader = () => {
  const { showModal, hideModal } = useGlobalModalContext();
  const {
    companyId,
    companies,
    switchCompany,
    hasMultiCompanies,
    isMultiViewActive,
  } = useCompany();

  const auth = useAuth();
  const { navigate, navigateWithCompanyId } = useNavigateWithSearchParam();
  const currentLocation = useLocation();
  const { t } = useTranslation();
  const [triggerFetchNotification, { data: notification }] =
    useLazyFetchNotificationsQuery();

  const theme = useSelector(getCurrentTheme);
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const selectedComapnyId =
    auth?.user?.attributes?.profile?.lastActiveCompanyId;
  const { userScope } = useCompany();
  useEffect(() => {
    if (selectedComapnyId) {
      triggerFetchNotification(userScope!);
    }
  }, [triggerFetchNotification, selectedComapnyId]);

  const [triggerLogoutGA] = useLogoutAnalyticsMutation();
  const [triggerClickGA] = useClickAnalyticsMutation();
  const signOut = () => {
    triggerLogoutGA();
    auth.signOut();
    navigate(RoutePath.LOGIN, { replace: true });
  };

  const onLanguage = () => {
    triggerClickGA(GA_LABEL.LANG_SETTING);
    showModal(MODAL_TYPES.LANG_MODAL);
  };

  const onNotificationClick = () => {
    triggerClickGA(GA_LABEL.NOTFICATION_SETTINGS);
    showModal(MODAL_TYPES.NOTIFICATION_MODAL, {
      notification,
    });
  };

  const onCoolIconClick = () => {
    window.open('https://chargelab.co/driver-and-site-host-terms#site-host');
  };
  const onCompanyInviteClick = useCallback(() => {
    showModal(MODAL_TYPES.ACCEPT_INVITE_MODAL, { onNavigate: navigate });
  }, [showModal, navigate]);

  const { data: invitesList } = useFetchDelegationInvitesQuery();

  const renderInviteCount = (isMenuItem: boolean = false) => {
    const alignStyle = isMenuItem ? '' : 'absolute -top-[8px] -right-[8px]';
    return invitesList?.length ? (
      <div
        className={`${alignStyle} flex justify-center rounded-full items-center w-[18px] h-[18px] text-xs bg-negative1 text-white z-[51]`}
      >
        {invitesList?.length}
      </div>
    ) : null;
  };

  useEffect(() => {
    if (invitesList?.length && localStorage.getItem('inviteModal')) {
      localStorage.removeItem('inviteModal');
      onCompanyInviteClick();
    }
  }, [invitesList, onCompanyInviteClick]);

  const renderSideBarMenuHeader = (transKey: string) => {
    return (
      <div key={transKey} className='pl-2 py-4 h-10'>
        <Label
          text={t(transKey)}
          type={LabelType.LABEL_XS}
          color={ColorType.GREY6}
        />
      </div>
    );
  };

  const sideBarItems = useMemo(() => {
    return sideBarMenus.map((group) => {
      return {
        ...group,
        items: group.items.map((item) => {
          const isSelected = getIsItemSelected(
            currentLocation.pathname,
            item.path,
          );

          const isDisabled =
            isMultiViewActive &&
            ![
              RoutePath.OVERVIEW,
              RoutePath.CHARGERS,
              RoutePath.REPORTS,
            ].includes(item.path);

          const color = isSelected
            ? theme.navigationSelectedColor
            : isDisabled
            ? ColorType.GREY3
            : ColorType.GREY6;
          return {
            ...item,
            isSelected,
            isDisabled,
            color,
          };
        }),
      };
    });
  }, [
    currentLocation.pathname,
    isMultiViewActive,
    theme.navigationSelectedColor,
  ]);

  const onSideMenuItemSelected = (index: number) => {
    const path = getPathByIndex(index, sideBarItems);
    navigateWithCompanyId(path, companyId, { replace: false });
  };

  const defaultMenuIndex = useMemo(() => {
    return getSelectedMenuIndex(currentLocation.pathname, sideBarItems);
  }, [currentLocation.pathname, sideBarItems]);

  const getItemWithTooltip = (item: any, path: string) => {
    const title = t(routes.find((route) => route.path === path)?.name || '');
    const labeText = (
      <Trans i18nKey='disabled_menu_tooltip' values={{ menu: title }} />
    );
    return (
      <Tooltip placement='top'>
        <TooltipTrigger className='w-full'>{item}</TooltipTrigger>
        <TooltipContent>
          <Label
            text={labeText}
            type={LabelType.LABEL_S}
            color={ColorType.WHITE}
            className='w-40'
          />
        </TooltipContent>
      </Tooltip>
    );
  };

  const renderSidebarSelect = () => {
    const items: any[] = [];
    sideBarItems.forEach((sideBarMenu) => {
      if (sideBarMenu.header) {
        items.push(renderSideBarMenuHeader(sideBarMenu.header));
      }
      if (sideBarMenu.items) {
        sideBarMenu.items.forEach((item) => {
          if (item.isDisabled) {
            items.push(
              getItemWithTooltip(
                <SelectItem
                  key={item.path}
                  label={t(item.path)}
                  iconBefore={item.icon}
                  labelType={LabelType.LABEL_S}
                  disabled={item.isDisabled}
                  labelColor={item.color}
                  iconColor={item.color}
                />,
                item.path,
              ),
            );
          } else {
            items.push(
              <SelectItem
                key={item.path}
                label={t(item.path)}
                iconBefore={item.icon}
                labelType={LabelType.LABEL_S}
                disabled={item.isDisabled}
                labelColor={item.color}
                iconColor={item.color}
              />,
            );
          }
        });
      }
    });

    return (
      <Select
        contentWidth={221}
        icon={menu}
        iconProps={{
          size: IconSize.SIZE_32x32,
        }}
        defaultIndex={defaultMenuIndex}
        onItemSelected={onSideMenuItemSelected}
      >
        {items}
      </Select>
    );
  };

  const renderAccountMenu = () => {
    return (
      <Menu
        label={t('account_label')}
        placement='bottom-end'
        buttonProps={{
          onClick: () => {
            triggerClickGA(GA_LABEL.ACCOUNT_SETTINGS);
          },
          icon: MyAccount,
          iconRightAlign: true,
          iconSize: IconSize.SIZE_32x32,
          buttonStyle: {
            height: '44px',
            minWidth: '152px',
          },
          labelType: LabelType.LABEL_S,
        }}
        OnOpenChange={setIsMenuOpen}
        mixWidth={240}
        contentClassName='z-[101]'
        dataTestId='profileMenu'
      >
        {selectedComapnyId && (
          <MenuItem
            iconBefore={BellNotification}
            label={t('notification_settings_label')}
            onClick={onNotificationClick}
            labelType={LabelType.LABEL_S}
            dataTestId='notificationSetting'
          />
        )}
        <MenuItem
          iconBefore={notificationIcon}
          label={t('company_invites')}
          onClick={onCompanyInviteClick}
          renderAfter={() => renderInviteCount(true)}
          labelType={LabelType.LABEL_S}
        />
        <MenuItem
          iconBefore={LanguageTranslate}
          label={`${t('profile_language')} : ${t(
            auth.user.attributes.profile?.sitehostDashboardLanguage,
          )}`}
          onClick={onLanguage}
          labelType={LabelType.LABEL_S}
          dataTestId='profileLanguage'
        />
        <MenuItem
          iconBefore={Document}
          label={t('terms_of_service')}
          onClick={onCoolIconClick}
          iconAfter={ExternalLink}
          labelType={LabelType.LABEL_S}
        />
        <Divider />
        <MenuItem
          iconBefore={SignoutLogout}
          label={t('profile_logout')}
          onClick={signOut}
          labelType={LabelType.LABEL_S}
          dataTestId='logoutAction'
        />
      </Menu>
    );
  };

  const hasAnyCompany = useMemo(() => {
    return companies && companies.length > 0;
  }, [companies]);

  return (
    <div className='flex flex-row justify-between bg-web_bg h-16 border-b border-grey3 xl:gap-4 px-6'>
      <div className='flex flex-row items-center gap-4'>
        <div className='flex flex-row h-10 items-center self-center divide-x divide-grey3 xl:hidden'>
          <div className='flex flex-row items-center h-10 gap-4 pr-4'>
            {hasAnyCompany && renderSidebarSelect()}
            <div className='self-center'>
              <img
                src={theme.networkLogo}
                style={{ width: 'auto', height: '40px' }}
                alt=''
                className='cursor-pointer'
                onClick={() =>
                  navigateWithCompanyId(`../${RoutePath.OVERVIEW}`, companyId, {
                    replace: true,
                  })
                }
              />
            </div>
          </div>
          <div className='flex items-center self-center h-10 pl-4 gap-2'>
            <Icon src={getRouteIcon(currentLocation.pathname)} />
            <Label
              text={t(getRouteTitle(currentLocation.pathname))}
              type={LabelType.LABEL_M}
              color={ColorType.BLACK}
            />
          </div>
        </div>
        <div className='hidden xl:flex'>
          <Label
            text={t(getRouteTitle(currentLocation.pathname))}
            type={LabelType.H5}
            color={ColorType.BLACK}
          />
        </div>
      </div>
      <div className='flex justify-end items-center gap-6'>
        <div className='xl:invisible'>
          {hasMultiCompanies && (
            <CompanySelect
              placement='bottom-end'
              buttonProps={{
                iconRightAlign: true,
                className: 'min-w-[160px]',
                buttonStyle: {
                  height: '44px',
                },
                topLeftLabel: t('company').toUpperCase(),
              }}
            />
          )}
        </div>
        <div className='relative' data-testid='div-account'>
          {!isMenuOpen && renderInviteCount()}
          {renderAccountMenu()}
        </div>
      </div>
    </div>
  );
};
