import { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { USER_ROLE, useAuth } from '../../../hooks';
import { useCompany } from '../../../hooks/useCompany';
import { useAllowedFeatures } from '../../../hooks/useFeaturePersonalization';
import {
  useLazyFetchChargerDetailQuery,
  useUpdateBulkChargersMutation,
} from '../../../services/charger.api';
import {
  useAddChargerLoadMutation,
  useCreateUnmanagedLoadMutation,
  useUpdatePMChargerMutation,
  useUpdateUnmanagedLoadMutation,
} from '../../../services/pm.api';
import { i18n } from '../../../services/translation/i18n';
import { SCOPE_TYPE } from '../../../services/utils';
import { BreakerLoad } from '../../../stores/types/pm.interface';
import {
  Button,
  ButtonSize,
  ButtonType,
  ColorType,
  GroupDirection,
  Label,
  LabelType,
  RadioGroup,
  useGlobalModalContext,
} from '../../_ui';
import {
  BREAKER_LOAD_TYPES,
  defaultLoadConfig,
  getChargersBreakerLoad,
  getUnmanagedBreakerLoad,
  loadRequestObjects,
  validateBreakerLoad,
} from '../utils';
import { LoadDetailsForm } from './LoadDetailsForm';
import { ManagedEVCharger } from './ManagedEVCharger';
import { UnmanagedLoad } from './UnmanagedLoad';

type LoadDataType = {
  parentBreakerIdName: any;
  loadData: BreakerLoad[];
  activeIndex: number;
};

export const LoadStepTwo = (props: any) => {
  const { t } = useTranslation();
  const { hideModal } = useGlobalModalContext();
  const auth = useAuth();
  const {
    data,
    companyId,
    notPowerManagedChargers,
    wizardState,
    setWizardState,
    isEdit = false,
  } = props;
  const {
    parentBreakerIdName,
    loadData = [{ ...defaultLoadConfig } as BreakerLoad],
    activeIndex = 0,
  } = wizardState as LoadDataType;
  const selectedItems: any[] = [];
  const { companies: companylist, queryStatus } = useCompany();
  const [triggerAddChargers, addChargerQueryResult] =
    useAddChargerLoadMutation();
  const [triggerAddUnmanagedLoad, addUnmanagedLoadQueryResult] =
    useCreateUnmanagedLoadMutation();
  const [updateBulkChargers, updateBulkChargerQueryResult] =
    useUpdateBulkChargersMutation();
  const [
    triggerFetchChargerDetail,
    { data: chargerDetails, isFetching: chargerLoading },
  ] = useLazyFetchChargerDetailQuery();

  const [triggerUpdateUnmanagedLoad, updateUnmanagedLoadQueryResult] =
    useUpdateUnmanagedLoadMutation();
  const [triggerUpdateChargers, updateChargerQueryResult] =
    useUpdatePMChargerMutation();
  const { allowPowermanEdit } = useAllowedFeatures();

  // charger options for the ev charger load
  const chargerOptions = useMemo(() => {
    if (queryStatus?.isLoading || !loadData || !companylist) {
      return [];
    }

    const selCompanyIndex = companylist?.findIndex(
      (company) => company.id === companyId,
    );
    // already selected chargers will be disabled for next load selection
    loadData.map(
      (load) => load.deviceId !== null && selectedItems.push(load.deviceId),
    );

    // notPowerManagedChargers charger is the list of the not already added chargers
    let evChargerOptions = notPowerManagedChargers;

    /*
    1. Support role users can add any charger, regardless of whether or not the model has been approved
       for power management use
    2. Testing companies: any model
    */
    if (
      auth.role === USER_ROLE.SUPPORT ||
      allowPowermanEdit ||
      !!companylist[selCompanyIndex].businessInfo?.testCompany
    ) {
      evChargerOptions = evChargerOptions.map((charger: any) => {
        return {
          id: charger.id,
          label: charger.displayName,
          selected: charger.id === loadData[activeIndex]?.deviceId,
          selectedIds: selectedItems,
        };
      });
    } else {
      // Non-testing companies: only models approved for power management
      // "powerManagementCompatible" key is used for the approved for power management charger
      evChargerOptions = notPowerManagedChargers.filter(
        (item: any) => item.powerManagementCompatible,
      );
    }

    /*
    add option for the self selected charger option
    because already added charger will not be available on existing option
    */
    if (isEdit && loadData[activeIndex].editChargerOption) {
      evChargerOptions.push(loadData[activeIndex].editChargerOption);
    }

    return evChargerOptions;
  }, [queryStatus?.isLoading, companylist, loadData, activeIndex]);

  // Breaker load type options
  const loadTypeOptions = useMemo(() => {
    if (!loadData) {
      return [];
    }
    return [
      {
        id: BREAKER_LOAD_TYPES.CHARGER,
        label: t('pm_load_type_one'),
        disabled: isEdit,
        selected: loadData[activeIndex].loadType === BREAKER_LOAD_TYPES.CHARGER,
      },
      {
        id: BREAKER_LOAD_TYPES.UNMANAGEDlOAD,
        label: t('pm_load_type_two'),
        disabled: isEdit,
        selected:
          loadData[activeIndex].loadType === BREAKER_LOAD_TYPES.UNMANAGEDlOAD,
      },
    ];
  }, [loadData, activeIndex, isEdit]);

  const handleInputChange = (key: string, value: any) => {
    if (key === 'breakerSpace' || key === 'breakerRating') {
      loadData[activeIndex].dedicatedBreaker = {
        ...loadData[activeIndex].dedicatedBreaker,
        [key]: value,
      };
    } else if (key === 'loadType') {
      loadData[activeIndex] = {
        ...loadData[activeIndex],
        [key]: value,
        deviceId: null,
      };
    } else if (key === 'deviceId') {
      loadData[activeIndex] = {
        ...loadData[activeIndex],
        [key]: value,
        chargerDetails: null,
      };
    } else {
      loadData[activeIndex] = {
        ...loadData[activeIndex],
        [key]: value,
      };
    }
    setWizardState({ ...wizardState, loadData });
  };

  const saveUnmanagedLoad = () => {
    const unmanagedLoads = loadData.filter(
      (item: BreakerLoad) => item.loadType !== BREAKER_LOAD_TYPES.CHARGER,
    );

    if (unmanagedLoads.length) {
      const unmanagedReqObj = loadRequestObjects(
        parentBreakerIdName,
        unmanagedLoads,
        BREAKER_LOAD_TYPES.UNMANAGEDlOAD,
      );

      triggerAddUnmanagedLoad({
        breakerId: parentBreakerIdName.parentBreakerId,
        body: unmanagedReqObj,
        scope: 'company',
        companyId: companyId || '',
        bulkOperation: true,
      });
    } else {
      hideModal();
    }
  };

  const saveAddChargerLoad = () => {
    const chargerLoads = loadData.filter(
      (item: BreakerLoad) => item.loadType === BREAKER_LOAD_TYPES.CHARGER,
    );

    if (chargerLoads.length) {
      const chargerReqObj = loadRequestObjects(
        parentBreakerIdName,
        chargerLoads,
        BREAKER_LOAD_TYPES.CHARGER,
      );

      triggerAddChargers({
        breakerId: parentBreakerIdName.parentBreakerId,
        body: chargerReqObj,
        scope: 'company',
        companyId: companyId || '',
        bulkOperation: true,
      });
    } else {
      saveUnmanagedLoad();
    }
  };

  const updateLoadData = () => {
    if (loadData[activeIndex].loadType === BREAKER_LOAD_TYPES.CHARGER) {
      const chargerReqObj = loadRequestObjects(
        parentBreakerIdName,
        loadData,
        BREAKER_LOAD_TYPES.CHARGER,
      );

      // filter unmanaged non-editable load and send into payload
      const nonEditableLoads = getChargersBreakerLoad(
        parentBreakerIdName,
        data.breaker,
        data.circuit.mainBreakers || [],
        loadData[activeIndex].editId || '',
      );

      const breakerId = data.breaker.dedicatedBreaker
        ? data.breaker.parentBreakerId
        : data.breaker.id;

      triggerUpdateChargers({
        breakerId,
        body: {
          breakerId,
          chargers: [...nonEditableLoads, ...chargerReqObj],
        },
        scope: 'company',
        companyId: companyId || '',
      });
    } else {
      const unmanagedReqObj = loadRequestObjects(
        parentBreakerIdName,
        loadData,
        BREAKER_LOAD_TYPES.UNMANAGEDlOAD,
      );
      // filter unmanaged non-editable load and send into payload
      const nonEditableLoads = getUnmanagedBreakerLoad(
        parentBreakerIdName,
        data.breaker,
        data.circuit.mainBreakers || [],
        loadData[activeIndex].editId || '',
      );

      const breakerId = data.breaker.dedicatedBreaker
        ? data.breaker.parentBreakerId
        : data.breaker.id;

      triggerUpdateUnmanagedLoad({
        breakerId,
        body: {
          breakerId,
          unmanagedLoads: [...nonEditableLoads, ...unmanagedReqObj],
        },
        scope: 'company',
        companyId: companyId || '',
      });
    }
  };

  const updateChargerDetails = () => {
    const chargerLoads = loadData.filter(
      (item: BreakerLoad) => item.loadType === BREAKER_LOAD_TYPES.CHARGER,
    );

    if (chargerLoads.length) {
      const bulkUpdateData: any = [];
      chargerLoads.forEach((item: BreakerLoad) => {
        const {
          deviceId,
          maxAmpsOverride,
          maxAmpsOverrideFactoryDefault,
          overrideZeroAmperage,
          supportZeroAmpsOverrideFactoryDefault,
        } = item;
        let updatedObj: any = { installationVoltage: data.circuit.lineVoltage };
        updatedObj = {
          ...updatedObj,
          maxAmpsOverride,
          maxAmpsOverrideFactoryDefault,
          supportZeroAmpsOverride: overrideZeroAmperage,
          supportZeroAmpsOverrideFactoryDefault,
        };
        if (Object.keys(updatedObj).length) {
          bulkUpdateData.push({
            ...updatedObj,
            deviceId,
          });
        }
      });

      if (bulkUpdateData.length) {
        updateBulkChargers({
          bulkChargers: bulkUpdateData,
          bulkOperation: bulkUpdateData.length > 1,
          autoPopulate: true,
          hideToast: true,
        });
      } else if (isEdit) {
        updateLoadData();
      } else {
        saveAddChargerLoad();
      }
    } else if (isEdit) {
      updateLoadData();
    } else {
      saveAddChargerLoad();
    }
  };

  useEffect(() => {
    if (addUnmanagedLoadQueryResult.isSuccess) {
      hideModal();
    } else if (addUnmanagedLoadQueryResult.isError) {
      hideModal();
    }
  }, [addUnmanagedLoadQueryResult]);

  useEffect(() => {
    if (addChargerQueryResult.isSuccess) {
      saveUnmanagedLoad();
    } else if (addChargerQueryResult.isError) {
      hideModal();
    }
  }, [addChargerQueryResult]);

  useEffect(() => {
    if (updateBulkChargerQueryResult.isSuccess) {
      if (isEdit) {
        updateLoadData();
      } else {
        saveAddChargerLoad();
      }
    }
  }, [updateBulkChargerQueryResult]);

  useEffect(() => {
    if (
      updateUnmanagedLoadQueryResult.isSuccess ||
      updateUnmanagedLoadQueryResult.isError
    ) {
      hideModal();
    }
  }, [updateUnmanagedLoadQueryResult]);

  useEffect(() => {
    if (
      updateChargerQueryResult.isSuccess ||
      updateChargerQueryResult.isError
    ) {
      hideModal();
    }
  }, [updateChargerQueryResult]);

  useEffect(() => {
    if (loadData[activeIndex].deviceId) {
      triggerFetchChargerDetail({
        scope: SCOPE_TYPE.COMPANY,
        id: loadData[activeIndex].deviceId!,
      });
    }
  }, [loadData[activeIndex].deviceId, activeIndex]);

  const totalMinAmperage = (ports: any[]) => {
    const total = ports.reduce((sum, port) => sum + (port?.minAmps || 0), 0);
    return total.toFixed(1);
  };

  useEffect(() => {
    if (chargerDetails) {
      const {
        model,
        type,
        ports,
        maxAmperage = 0,
        maxAmpsOverride,
        supportZeroAmpsOverride,
        supportZeroAmps = false,
      } = chargerDetails;
      const maxAmpsOverrideFactoryDefault =
        typeof maxAmpsOverride !== 'undefined' && maxAmpsOverride !== null;
      const supportZeroAmpsOverrideFactoryDefault =
        typeof supportZeroAmpsOverride !== 'undefined' &&
        supportZeroAmpsOverride !== null;
      loadData[activeIndex] = {
        ...loadData[activeIndex],
        model,
        type,
        maxAmpsOverrideFactoryDefault,
        maxAmperage,
        maxAmpsOverride,
        minAmp: totalMinAmperage(ports),
        overrideZeroAmperage: supportZeroAmpsOverride,
        zeroAmpSupport: supportZeroAmps,
        supportZeroAmpsOverrideFactoryDefault,
      };

      setWizardState({ ...wizardState, loadData });
    }
  }, [chargerDetails, chargerLoading]);

  return (
    <div className='flex flex-col'>
      {!isEdit && (
        <div className='flex flex-row gap-2 pb-4'>
          <Label
            type={LabelType.LABEL_L}
            color={ColorType.GREY6}
            text={i18n.t('current_node_number_1', {
              current: activeIndex + 1,
            })}
          />
          <Label
            type={LabelType.LABEL_L}
            color={ColorType.GREY5}
            text={i18n.t('current_node_number_2', {
              total: loadData.length || 0,
            })}
          />
        </div>
      )}
      <div className='divide-y divide-grey3'>
        <div className='flex flex-col pb-4 gap-2'>
          <Label
            type={LabelType.LABEL_M}
            color={ColorType.BLACK}
            text={t('pm_load_type_label')}
          />

          <RadioGroup
            direction={GroupDirection.Horizontal}
            defaultItems={loadTypeOptions}
            itemHeight='20'
            onChange={(optionItems: any) => {
              console.log('selectedItem', optionItems);
              const selItem = optionItems.filter((item: any) => item.selected);
              handleInputChange(
                'loadType',
                selItem.length ? selItem[0].id : null,
              );
            }}
            dataTestId='loadType'
          />
        </div>
        {loadData[activeIndex].loadType && (
          <div className='flex flex-col pt-4 gap-4'>
            {loadData[activeIndex].loadType === BREAKER_LOAD_TYPES.CHARGER && (
              <ManagedEVCharger
                loadData={loadData}
                activeIndex={activeIndex}
                dropdownOptions={chargerOptions}
                handleInputChange={handleInputChange}
                isLoading={chargerLoading}
                isEdit={isEdit}
              />
            )}
            {loadData[activeIndex].loadType ===
              BREAKER_LOAD_TYPES.UNMANAGEDlOAD && (
              <UnmanagedLoad
                loadData={loadData}
                activeIndex={activeIndex}
                handleInputChange={handleInputChange}
                data={data}
                isEdit={isEdit}
              />
            )}
          </div>
        )}
        {((loadData[activeIndex].loadType === BREAKER_LOAD_TYPES.CHARGER &&
          loadData[activeIndex].deviceId) ||
          loadData[activeIndex].loadType ===
            BREAKER_LOAD_TYPES.UNMANAGEDlOAD) && (
          // eslint-disable-next-line react/jsx-indent
          <div className='flex flex-col pt-4 gap-4'>
            <LoadDetailsForm
              loadData={loadData}
              activeIndex={activeIndex}
              handleInputChange={handleInputChange}
              data={data}
            />
          </div>
        )}
      </div>
      {!isEdit && (
        <div className='flex flex-row gap-2 mb-5'>
          <Button
            label={t('previous_load_label')}
            type={ButtonType.TERTIARY}
            size={ButtonSize.SMALL_FULL}
            onClick={() => {
              setWizardState({
                ...wizardState,
                activeIndex: activeIndex - 1,
              });
            }}
            disabled={
              activeIndex === 0 ||
              addChargerQueryResult.isLoading ||
              addUnmanagedLoadQueryResult.isLoading
            }
          />
          {activeIndex !== loadData.length - 1 && (
            <Button
              label={t('next_load_label')}
              type={ButtonType.PRIMARY}
              size={ButtonSize.SMALL_FULL}
              onClick={() => {
                setWizardState({
                  ...wizardState,
                  activeIndex: activeIndex + 1,
                });
              }}
              disabled={
                addChargerQueryResult.isLoading ||
                addUnmanagedLoadQueryResult.isLoading ||
                validateBreakerLoad(
                  loadData,
                  activeIndex,
                  data.circuit.mainBreakers,
                  isEdit,
                )
              }
            />
          )}
          {activeIndex === loadData.length - 1 && (
            <Button
              dataTestId='saveLoad'
              isLoading={
                addChargerQueryResult.isLoading ||
                addUnmanagedLoadQueryResult.isLoading
              }
              label={t('save')}
              type={ButtonType.PRIMARY}
              size={ButtonSize.SMALL_FULL}
              onClick={updateChargerDetails}
              disabled={validateBreakerLoad(
                loadData,
                activeIndex,
                data.circuit.mainBreakers,
                isEdit,
              )}
              isSumbit
            />
          )}
        </div>
      )}
      {isEdit && (
        <div className='flex flex-row gap-2 mb-5'>
          <Button
            dataTestId='updateLoad'
            isLoading={
              updateUnmanagedLoadQueryResult.isLoading ||
              updateChargerQueryResult.isLoading
            }
            label={t('save')}
            type={ButtonType.PRIMARY}
            size={ButtonSize.SMALL_FULL}
            onClick={updateChargerDetails}
            disabled={
              validateBreakerLoad(
                loadData,
                activeIndex,
                data.circuit.mainBreakers,
                isEdit,
              ) || chargerLoading
            }
            isSumbit
          />
        </div>
      )}
    </div>
  );
};
