import React, { createContext, useEffect, useMemo, useState } from 'react';
import { StringParam, useQueryParam } from 'use-query-params';
import { useFetchCompanyDetailQuery } from '../services/company.api';
import { Company } from '../stores/types';
import { useAuth } from './useAuth';
import { useCompany } from './useCompany';

type ContextType = {
  showTerminal: boolean;
  isPayterConnected: boolean;
  company: Company | undefined;
  isCompanyLoading: boolean;
  isFetchCompanySuccess: boolean;
  setShowTerminal: Function;
  setIsPayterConnected: Function;
};

const initalState: ContextType = {
  showTerminal: false,
  isPayterConnected: false,
  company: undefined,
  isCompanyLoading: false,
  isFetchCompanySuccess: false,
  setShowTerminal: () => {},
  setIsPayterConnected: () => {},
};

export const payterContext = createContext(initalState);

interface IPROPS {
  children: React.ReactNode;
}

export const PayterProvider = ({ children }: IPROPS) => {
  const [showTerminal, setShowTerminal] = useState(true);
  const [isPayterConnected, setIsPayterConnected] = useState(false);

  const [companyId] = useQueryParam('companyId', StringParam);
  const { userScope } = useCompany();
  const { company, isFetchCompanySuccess, isCompanyLoading } =
    useFetchCompanyDetailQuery(
      { id: companyId!, scope: userScope! },
      {
        selectFromResult: (endPoint) => ({
          company: endPoint.data ? endPoint.data : ({} as Company),
          isFetchCompanySuccess: endPoint.isSuccess,
          isCompanyLoading: endPoint.isLoading || endPoint.isFetching,
        }),
      },
    );

  useEffect(() => {
    if (!!company.payterAccountIdentifier && isFetchCompanySuccess) {
      setIsPayterConnected(true);
    } else {
      setIsPayterConnected(false);
    }
  }, [company.payterAccountIdentifier, isFetchCompanySuccess]);

  const values = useMemo(() => {
    return {
      company,
      showTerminal,
      isPayterConnected,
      setShowTerminal,
      setIsPayterConnected,
      isCompanyLoading,
      isFetchCompanySuccess,
    };
  }, [
    showTerminal,
    setShowTerminal,
    isPayterConnected,
    company,
    isCompanyLoading,
    isFetchCompanySuccess,
  ]);

  return (
    <payterContext.Provider value={values}>{children}</payterContext.Provider>
  );
};
