/* eslint-disable @typescript-eslint/ban-types */
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useCompany } from '../../../hooks/useCompany';
import { useFetchLocationsQuery } from '../../../services/location.api';
import { Dropdown, DropdownType, LabelType } from '../../_ui';

interface IProps {
  chargers: string[];
  setChargers: Function;
  setIsLocationDifferent: Function;
  setIsChargerChanged: Function;
}
const SelectCharger = ({
  chargers,
  setChargers,
  setIsChargerChanged,
  setIsLocationDifferent,
}: IProps) => {
  const { t } = useTranslation();

  const { userScope } = useCompany();
  const { locations } = useFetchLocationsQuery(
    { scope: userScope! },
    {
      selectFromResult: (endPoint) => ({
        locations: endPoint?.data ? endPoint.data.entities : [],
      }),
    },
  );

  const chargersDrodownList = useMemo(() => {
    return locations
      .map((location) => {
        const children = location.chargers?.map((charger: any) => {
          return {
            id: charger.id,
            label: charger.displayName,
            selected: chargers?.includes(charger.id),
          };
        });

        if (children?.length === 0) {
          return null;
        }
        return {
          id: location.id,
          label: location.name,
          children: children || [],
          selected: children?.length
            ? children?.every((child) => child.selected)
            : false,
        };
      })
      .filter((location) => location);
  }, [chargers, locations]);

  const handleChargersChange = (items: any) => {
    setIsChargerChanged(true);
    const newChargers: string[] = [];
    items.forEach((item: any) => {
      item.children.forEach((child: any) => {
        if (child.selected) {
          newChargers.push(child.id);
        }
      });
    });
    const selectedLocations = chargersDrodownList.filter(
      (chargersList) =>
        !!chargersList?.children.find((charger) =>
          newChargers.includes(charger.id),
        ),
    );
    setIsLocationDifferent(selectedLocations.length > 1);
    setChargers(newChargers);
  };

  return (
    <div className='w-full'>
      <Dropdown
        placeholder={t('select_charger_s')}
        placeholderLabelType={LabelType.BODY3_G4}
        headerWidth='100%'
        items={chargersDrodownList}
        type={DropdownType.CHECKBOX_TREE}
        showPillHeader
        onItemClick={(items: any) => handleChargersChange(items)}
        showFooter
        placement='top-start'
        contentDivWidth={476}
      />
    </div>
  );
};

export default SelectCharger;
