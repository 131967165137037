import { memo, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { RoutePath } from '../../routes';
import {
  Button,
  ButtonType,
  Card,
  ColorType,
  DoughnutChart,
  Label,
  LabelType,
} from '../_ui';
import { ButtonSize } from '../_ui/Button.component';

// API
import { useLazyFetchChargersSummaryStatusQuery } from '../../services/charger.api';

// Constants
import { useCompany } from '../../hooks/useCompany';
import { useNavigateWithSearchParam } from '../../hooks/useNavigateWithSearchParam';
import { CHARGER_SUMMARY_STATUS } from '../../stores/types/chargers.interface';

interface InputProps {
  locationId: string | null | undefined;
  companyIds: (string | null)[] | null | undefined;
}

export const ChargerStatusChart = memo(
  ({ locationId, companyIds }: InputProps) => {
    const { t } = useTranslation();

    const {
      companyId: companyIdSearchParam,
      userScope,
      isMultiViewActive,
    } = useCompany();
    const { navigateWithCompanyId } = useNavigateWithSearchParam();
    const [
      triggerFetchChargersSummary,
      { data, isFetching: isChargerLoading },
    ] = useLazyFetchChargersSummaryStatusQuery({ pollingInterval: 30000 });

    const chartData = useMemo(() => {
      return (
        data?.siteHostOverviewStatus.map((status) => {
          switch (status.id) {
            case CHARGER_SUMMARY_STATUS.COMING_SOON:
              return {
                label: t('coming_soon'),
                value: status.count,
                color: ColorType.GREY4,
              };
            case CHARGER_SUMMARY_STATUS.AVAILABLE:
              return {
                label: t('available'),
                value: status.count,
                color: ColorType.POSITIVE1,
              };
            case CHARGER_SUMMARY_STATUS.CHARGING:
              return {
                label: t('in_use'),
                value: status.count,
                color: ColorType.ACCENT1,
              };
            case CHARGER_SUMMARY_STATUS.OFFLINE:
              return {
                label: t('offline'),
                value: status.count,
                color: ColorType.WARNING1,
              };
            case CHARGER_SUMMARY_STATUS.OUT_OF_ORDER:
              return {
                label: t('out_of_order'),
                value: status.count,
                color: ColorType.NEGATIVE1,
              };
            default:
              return {
                label: 'unknown status',
                value: status.count,
                color: ColorType.BLACK,
              };
          }
        }) || []
      );
    }, [data?.siteHostOverviewStatus, t]);

    useEffect(() => {
      if (isMultiViewActive) {
        // multi company
        triggerFetchChargersSummary({
          'filter_in[companyId]': companyIds,
          scope: userScope,
        });
      } else {
        // single company
        triggerFetchChargersSummary({
          'filter_eq[locationId]': locationId,
          companyId: companyIdSearchParam!,
          scope: userScope,
        });
      }
    }, [
      companyIds,
      locationId,
      companyIdSearchParam,
      triggerFetchChargersSummary,
      userScope,
      isMultiViewActive,
    ]);

    const handleViewChargersClick = () => {
      navigateWithCompanyId(`../${RoutePath.CHARGERS}`, companyIdSearchParam, {
        replace: true,
      });
    };
    return (
      <Card className='h-full'>
        <div className='flex w-full mb-4'>
          <div className='flex w-1/2 items-center justify-center'>
            <Label
              type={LabelType.H4}
              color={ColorType.BLACK}
              text={t('overview_charger_status')}
              className='whitespace-nowrap'
            />
          </div>
          <div className='flex justify-end w-1/2'>
            <Button
              size={ButtonSize.SMALL}
              label={t('overview_view_chargers')}
              type={ButtonType.TERTIARY}
              onClick={() => handleViewChargersClick()}
              dataTestId='viewChargerButton'
            />
          </div>
        </div>
        <DoughnutChart
          items={chartData}
          className='flex h-36'
          isLoading={isChargerLoading}
        />
      </Card>
    );
  },
);
