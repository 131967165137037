import React, { memo } from 'react';

interface DividerProps {
  className?: string;
  margin?: string;
}

export const Divider = memo(({ className, margin = '4px' }: DividerProps) => {
  return (
    <div
      className={`border-t border-grey2 ${className} `}
      style={{
        marginTop: margin,
        marginBottom: margin,
      }}
    />
  );
});

export default Divider;
