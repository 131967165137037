import i18n from 'i18next';
import React from 'react';
import {
  ChargerEV,
  CompanyBuilding,
  Dashboard,
  LockPrivacy,
  PayoutMoney,
  Power as PowerIcon,
  Pricing as PricingIcon,
  RFIDCard,
  Reports,
  Vehicle,
} from './assets/icons';
import { Access } from './components/Access/Access.component';
import { Chargers } from './components/Charger';
import { ChargerDetail } from './components/Charger/ChargerDetail.component';
import { Payouts } from './components/Payouts/Payouts.component';
import { Power } from './components/Power/Power.component';
import { EditPricingRules } from './components/Pricing/EditPricingRules.component';
import { Pricing } from './components/Pricing/Pricing.component';
import { Rfid } from './components/RFID/Rfid.component';
import { VehicleDetails } from './components/Vehicles/VehicleDetails.component';
import { Vehicles } from './components/Vehicles/Vehicles.component';
import NoCompanyAccess from './components/_ui/No-company-access.component';
import { Company } from './components/company/Company.component';
import { Overview } from './components/overview';
import { Report } from './components/reports/Report.component';

export enum RoutePath {
  LOGIN = '/login',
  OVERVIEW = 'overview',
  CHARGERS = 'chargers',
  CHARGER_DETAIL = 'chargers/:chargerId',
  CHARGER_MESSAGE = '/:chargerId/:receivedTime/message',
  PRICING = 'pricing',
  PRICING_EDIT = 'pricingedit',
  ACCESS = 'access',
  COMPANY = 'company',
  PAYOUT = 'payout',
  RFID = 'rfid',
  LEARNING = 'learing',
  HELP = 'help',
  VEHICLES = 'vehicles',
  NO_ACCESS = 'noaccess',
  VEHICLE_DETAIL = 'vehicles/:vehicleId',
  NO_ROUTE_PERMISSION = '/noRoutePermission',
  POWER = 'power',
  REPORTS = 'reports',
  NOT_FOUND = '/pageNotFound',
}

export const multiCompanyRoutes = [
  '/overview',
  '/chargers',
  '/chargers/:chargerId',
  '/reports',
];

export interface Route {
  name: string;
  path: RoutePath;
  component: React.ComponentType<any>;
  icon: string;
}

export const routes: Route[] = [
  {
    name: 'overview',
    path: RoutePath.OVERVIEW,
    component: Overview,
    icon: Dashboard,
  },
  {
    name: 'chargers',
    path: RoutePath.CHARGERS,
    component: Chargers,
    icon: ChargerEV,
  },
  {
    name: 'charger_detail',
    path: RoutePath.CHARGER_DETAIL,
    component: ChargerDetail,
    icon: ChargerEV,
  },
  {
    name: 'pricing',
    path: RoutePath.PRICING,
    component: Pricing,
    icon: PricingIcon,
  },
  {
    name: 'pricing',
    path: RoutePath.PRICING_EDIT,
    component: EditPricingRules,
    icon: PricingIcon,
  },
  {
    name: 'access',
    path: RoutePath.ACCESS,
    component: Access,
    icon: LockPrivacy,
  },
  {
    name: 'power',
    path: RoutePath.POWER,
    component: Power,
    icon: PowerIcon,
  },
  {
    name: 'payout',
    path: RoutePath.PAYOUT,
    component: Payouts,
    icon: PayoutMoney,
  },
  {
    name: 'company',
    path: RoutePath.COMPANY,
    component: Company,
    icon: CompanyBuilding,
  },
  // {
  //   name:
  //   path: RoutePath.LEARNING,
  //   component: ,
  // },
  {
    name: 'rfid',
    path: RoutePath.RFID,
    component: Rfid,
    icon: RFIDCard,
  },
  // {
  //   name:
  //   path: RoutePath.HELP,
  //   component: ,
  // },
  {
    name: 'vehicles',
    path: RoutePath.VEHICLES,
    component: Vehicles,
    icon: Vehicle,
  },
  {
    name: 'vehicles',
    path: RoutePath.VEHICLE_DETAIL,
    component: VehicleDetails,
    icon: Vehicle,
  },
  {
    name: 'noaccess',
    path: RoutePath.NO_ACCESS,
    component: NoCompanyAccess,
    icon: Dashboard,
  },
  {
    name: 'reports',
    path: RoutePath.REPORTS,
    component: Report,
    icon: Reports,
  },
];

export type GroupItem = {
  path: RoutePath;
  icon: string;
  index?: number;
  ignoreClick?: boolean;
  isDisabled?: boolean;
  isSelected?: boolean;
  color?: string;
};
type SideBarMenu = {
  header?: string;
  items: GroupItem[];
};
export const sideBarMenus: SideBarMenu[] = [
  {
    items: [
      {
        path: RoutePath.OVERVIEW,
        icon: Dashboard,
        index: 0,
      },
    ],
  },
  {
    header: 'side_bar_charger_management',
    items: [
      {
        path: RoutePath.CHARGERS,
        icon: ChargerEV,
        index: 1,
      },
      { path: RoutePath.PRICING, icon: PricingIcon, index: 2 },
      { path: RoutePath.ACCESS, icon: LockPrivacy, index: 3 },
      { path: RoutePath.POWER, icon: PowerIcon, index: 4 },
    ],
  },
  {
    header: 'side_bar_fleet_management',
    items: [{ path: RoutePath.VEHICLES, icon: Vehicle, index: 5 }],
  },
  {
    header: 'side_bar_admin',
    items: [
      { path: RoutePath.COMPANY, icon: CompanyBuilding, index: 6 },
      { path: RoutePath.REPORTS, icon: Reports, index: 7 },
      { path: RoutePath.PAYOUT, icon: PayoutMoney, index: 8 },
      { path: RoutePath.RFID, icon: RFIDCard, index: 9 },
    ],
  },
];

export const getBaseRoute = (fullPath: string) => {
  const arr = fullPath.split('/');
  return arr?.length >= 2 ? arr[1] : arr[0];
};

export const getRouteTitle = (fullpath: string) => {
  if (fullpath === '/' || fullpath === '/noaccess') {
    return 'overview';
  }
  if (fullpath === '/power') {
    return i18n.t('power_management');
  }

  const baseRoute = getBaseRoute(fullpath);
  return routes.find((route) => route.path === baseRoute)?.name || '';
};

export const getRouteIcon = (fullpath: string) => {
  const baseRoute = getBaseRoute(fullpath);
  return routes.find((route) => route.path === baseRoute)?.icon || Dashboard;
};
