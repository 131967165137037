import { Phases } from '../../../assets/icons';
import { IconSize } from '../../../constant/IconSize.constant';
import { ColorType, Icon, Label, LabelType } from '../../_ui';
import { transformElectricSupply, transformWiring } from '../utils';

interface Props {
  value: string;
  isElectricSupply: boolean;
}

export const Phase = ({ value, isElectricSupply }: Props) => {
  return (
    <div className='flex flex-row gap-x-1 items-end'>
      <Icon src={Phases} size={IconSize.SIZE_20x20} />
      <Label
        className='pl-1'
        text={
          isElectricSupply
            ? transformElectricSupply(value)
            : transformWiring(value)
        }
        type={LabelType.LABEL_XS_MEDIUM}
        color={ColorType.GREY5}
      />
    </div>
  );
};
