/* eslint-disable no-useless-escape */
/* eslint-disable no-control-regex */

import { Invite } from '../../../stores/types';
import { IdleFeeDetails } from '../../Pricing/PriceForm/PriceForm.component';

/* eslint-disable prefer-regex-literals */
const regex = new RegExp(
  /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,10}))$/,
);
export const validateEmail = (value: any) => {
  return regex.test(value);
};
export const validateOcppId = (value: any) => {
  const ocppRegex = /^([a-zA-Z0-9-*_=+.|@])+$/;
  return ocppRegex.test(value);
};

const regexCurrency = new RegExp(/^[0-9]\d{0,2}(?:,?\d{3})*(?:\.\d{0,2})?$/);
export const validateCurrency = (value: any, greatThanZero: boolean = true) => {
  if (greatThanZero && Number(value) <= 0) {
    return false;
  }
  return regexCurrency.test(value);
};

const regexEmailDomain = new RegExp(
  /^$|^([a-zA-Z0-9_\-\.]+)\.([a-zA-Z]{2,10})$/,
);

export const validateDomainName = (value: any) => {
  if (value) {
    return regexEmailDomain.test(value);
  }
};

export const validateDisplayId = (value: any) => {
  const reg = /^[a-zA-Z0-9*-_|@]+$/;
  return reg.test(value);
};

export const sortInvitedCompanyByDate = (invitesList: Invite[]) => {
  const sortedStats = invitesList ? [...invitesList] : [];

  return sortedStats.sort((a: any, b: any) =>
    new Date(a.dateRequested) < new Date(b.dateRequested) ? 1 : -1,
  );
};

export const validateIdleFees = (
  value: any,
  currency: any,
  greatThanZero: boolean = true,
) => {
  if (greatThanZero && Number(value) <= 0) {
    return false;
  }
  if (
    !Number.isNaN(Number(value)) &&
    (currency === 'CAD' || currency === 'USD') &&
    Number(value) > 1.5
  ) {
    return false;
  }
  if (
    !Number.isNaN(Number(value)) &&
    currency === 'JMD' &&
    Number(value) > 250
  ) {
    return false;
  }
  if (
    !Number.isNaN(Number(value)) &&
    currency === 'BBD' &&
    Number(value) > 3.0
  ) {
    return false;
  }
  return regexCurrency.test(value);
};

export const checkIdleFeeError = (
  idleFeeDetails: IdleFeeDetails,
  currency: any,
) => {
  const { idleRate } = idleFeeDetails;
  if (!idleFeeDetails.enable) {
    return false;
  }
  if (currency === 'CAD' || currency === 'USD') {
    return !Number.isNaN(Number(idleRate)) && Number(idleRate) > 1.5;
  }
  if (currency === 'JMD') {
    return !Number.isNaN(Number(idleRate)) && Number(idleRate) > 250;
  }
  if (currency === 'BBD') {
    return !Number.isNaN(Number(idleRate)) && Number(idleRate) > 3.0;
  }
};
export const MODAL_LAYER_INDEX = 1000;
