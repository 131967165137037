export interface BusinessInfo {
  channelPartnerId?: string;
  testCompany?: boolean;
  ela?: boolean;
}

export enum BankInfoProvided {
  PROVIDED = 'PROVIDED',
  MISSING = 'MISSING',
  NOT_REQUIRED = 'NOT_REQUIRED',
}

export interface Company {
  id: string;
  currency: string;
  name: string;
  unit?: string;
  streetNumber?: number;
  street?: string;
  zipOrPostalCode?: string;
  city?: string;
  stateOrRegion?: string;
  isoCountryCode?: string;
  contactEmail?: string;
  contactPhone?: string;
  contactName?: string;
  serviceFeePercentage?: string;
  whiteLabellingId?: string;
  adminUserGroupId: string;
  noOfChargers?: number;
  noOfLocations?: number;
  virtualUserIds?: string[];
  defaultPriceModelId?: string;
  businessInfo?: BusinessInfo;
  bankInfoProvided?: BankInfoProvided;
  payterAccountIdentifier?: boolean;
}

export interface sendBankInfoReminderPayload {
  companyId: string;
  companyName: string;
}
